import {call, debounce, put} from 'redux-saga/effects';
import Store from '../../store';
import lodashChunk from 'lodash/chunk';
import {getUsersInfoAC, getUsersInfoSuccessAC} from '../../actions/common';
import {getUsersByIdsAPI} from '../../../api/user';

function* getUsersInfoFlow() {
  try {
    const users = Store.getState().common.users;
    if (users) {
      const ids = Object.values(users)
        .filter(item => item.isFetching)
        .map(item => item.id);
      const chunkedArray = lodashChunk(ids, 20);
      for (let i = 0; i < chunkedArray.length; i++) {
        const arr = chunkedArray[i];
        const {data} = yield call(getUsersByIdsAPI, arr);
        yield put(getUsersInfoSuccessAC(arr, data || []));
      }
    }
  } catch (e) {
  }
}

function* Watcher() {
  yield debounce(300, getUsersInfoAC().type, getUsersInfoFlow);
}

export default Watcher;
