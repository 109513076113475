import createReducer from '../createReducer';
import {GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS} from '../../actions';

const initialState = {
  unread: [],
  totalUnread: 0,
  all: [],
  total: 0,
  isFetching: true,
};

const handlers = {
  [GET_NOTIFICATIONS]: (state) => {
    return {
      ...state,
      isFetching: true,
    }
  },
  [GET_NOTIFICATIONS_SUCCESS]: (state, action) => {
    return {
      ...state,
      unread: action.data.unread,
      totalUnread: action.data.totalUnread,
      all: action.data.all,
      total: action.data.total,
      isFetching: false,
    }
  },
};

export default createReducer(initialState, handlers);
