import React from 'react';
import {connect} from 'react-redux';
import {resetPasswordAPI} from '../../../api/auth';
import { Form } from '@ant-design/compatible';
import { Alert, Button, Input, message } from 'antd';
import {getErrorMessage} from '../../../common/helpers';
import qs from 'qs';
import {history} from '../../../history';

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  componentDidMount() {
    this.getToken();
  }

  getToken = () => {
    const params = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    if (!params.token) {
      history.replace('/login');
      return null;
    }
    return params.token;
  };

  handleConfirmBlur = e => {
    const {value} = e.target;
    this.setState({confirmDirty: this.state.confirmDirty || !!value});
  };

  compareToFirstPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Mật khẩu không khớp!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.handleResetPassword(values);
      }
    });
  };

  handleResetPassword = (formData) => {
    this.setState({
      isSubmitting: true,
    });
    resetPasswordAPI({
      ...formData,
      token: this.getToken(),
    }).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đặt mật khẩu thành công! Bạn có thể đăng nhập!');
      history.replace('/login');
    }).catch(error => {
      const errorMessage = getErrorMessage(error);
      if (errorMessage === 'Token not existed') {
        message.error('Liên kết đặt mật khẩu đã hết hạn hoặc không tồn tại!');
      } else {
        message.error(errorMessage);
      }
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {form: {getFieldDecorator}, isOnline} = this.props;
    const {isSubmitting} = this.state;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {
          !isOnline &&
          <Alert
            type="error"
            description="Vui lòng kiểm tra kết nối internet của bạn"
            message="Không có mạng"
          />
        }
        <Form.Item label="Mật khẩu mới" hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu!',
              },
              {
                min: 6,
                message: 'Mật khẩu ít nhất 6 ký tự!',
              },
              {
                max: 100,
                message: 'Mật khẩu tối đa 100 ký tự!',
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password/>)}
        </Form.Item>
        <Form.Item label="Nhập lại mật khẩu mới" hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu!',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={this.handleConfirmBlur}/>)}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={isSubmitting}
          >
            Đặt lại mật khẩu
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isOnline: state.app.isOnline,
});

export default connect(mapStateToProps)(Form.create({name: 'reset_password'})(ResetPassword));
