import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {forgotPasswordAPI, loginAPI} from '../../../api/auth';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Alert, Button, Input, message } from 'antd';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import {loginSuccessAC} from '../../../redux/actions/user';
import {Link} from 'react-router-dom';
import {getErrorMessage} from '../../../common/helpers';
import './Login.scss';

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isForgotPassword: false,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.isForgotPassword) {
          this.handleForgotPassword(values);
        } else {
          this.handleLogin(values);
        }
      }
    });
  };

  toggleForgotPassword = () => {
    this.setState(prevState => {
      return {
        isForgotPassword: !prevState.isForgotPassword,
      };
    });
  };

  handleForgotPassword = (formData) => {
    this.setState({
      isSubmitting: true,
    });
    forgotPasswordAPI(formData).then(() => {
      this.setState({
        isSubmitting: false,
        isForgotPassword: false,
      });
      message.success('Thành công! Vui lòng kiểm tra email để đặt lại mật khẩu!');
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  handleLogin = (formData) => {
    this.setState({
      isSubmitting: true,
    });
    loginAPI(formData).then(res => {
      this.setState({
        isSubmitting: false,
      });
      this.props.dispatch(loginSuccessAC({
        ...res.data,
      }));
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {form: {getFieldDecorator}, isOnline} = this.props;
    const {isSubmitting, isForgotPassword} = this.state;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {
          !isOnline &&
          <Alert
            type="error"
            description="Vui lòng kiểm tra kết nối internet của bạn"
            message="Không có mạng"
          />
        }
        <Form.Item label="Tên truy cập">
          {getFieldDecorator('username', {
            rules: [{
              required: true,
              message: 'Vui lòng nhập tên truy cập!'
            }],
          })(
            <Input
              prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
              placeholder="Tên truy cập hoặc email"
            />,
          )}
        </Form.Item>
        {
          !isForgotPassword &&
          <Fragment>
            <Form.Item label="Mật khẩu">
              {getFieldDecorator('password', {
                rules: [{
                  required: true,
                  message: 'Vui lòng nhập mật khẩu!'
                }],
              })(
                <Input
                  prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                  type="password"
                  placeholder="Mật khẩu"
                />,
              )}
            </Form.Item>
            <Form.Item>
              <HyperLink className="login-form-forgot" onClick={this.toggleForgotPassword}>
                Quên mật khẩu
              </HyperLink>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={isSubmitting}
              >
                Đăng nhập
              </Button>
              Bạn chưa có tài khoản? <Link to={'/register'}>Đăng ký ngay!</Link>
            </Form.Item>
          </Fragment>
        }
        {
          isForgotPassword &&
          <Form.Item>
            <HyperLink onClick={this.toggleForgotPassword}>
              Đăng nhập
            </HyperLink>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={isSubmitting}
            >
              Lấy mật khẩu
            </Button>
            Bạn chưa có tài khoản? <Link to={'/register'}>Đăng ký ngay!</Link>
          </Form.Item>
        }
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isOnline: state.app.isOnline,
});

export default connect(mapStateToProps)(Form.create({name: 'login_form'})(Login));
