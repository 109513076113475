import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import moment from 'moment';

class NotificationEventReminder extends React.PureComponent {
  render() {
    const {notification: {message, params}, markAsRead} = this.props;
    if (!params) {
      return (
        <span>{message}</span>
      );
    }
    const date = params.eventFromDateTime ? moment(params.eventFromDateTime).format("DD/MM/YYYY") : "";
    return (
      <Link to={`/calendar`} className="notification-item" onClick={markAsRead}>
        <strong>{params.eventTitle}</strong> sẽ đến hạn vào <strong>{date}</strong>
      </Link>
    );
  }
}

NotificationEventReminder.propTypes = {
  notification: PropTypes.any,
  markAsRead: PropTypes.func,
};

export default NotificationEventReminder;
