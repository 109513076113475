import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHTML from 'sanitize-html';
import Broadcaster from '../../helpers/broadcaster';
import {BROADCAST_EVENTS} from '../../constants/broadcastEvents';

const sanitize = (html) => {
  return sanitizeHTML(html, {
    allowedTags: sanitizeHTML.defaults.allowedTags.concat(['img']),
    allowedAttributes: {
      ...sanitizeHTML.defaults.allowedAttributes,
      'table': ['border'],
      '*': ['style', 'colspan', 'rowspan', 'data-*', 'class'],
    },
    allowedSchemesByTag: {
      img: ['data']
    }
  });
};

class HTMLView extends React.PureComponent {
  containerRef = React.createRef();

  componentDidMount() {
    this.updateEllipsis();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateEllipsis();
  }

  onClick = (event) => {
    const target = event.target;
    if (`${target.tagName}`.toUpperCase() === 'IMG') {
      Broadcaster.broadcast(BROADCAST_EVENTS.OPEN_IMAGE_LIGHT_BOX, [target.src]);
    }
  };

  updateEllipsis = () => {
    try {
      const {maxRows} = this.props;
      if (!maxRows) {
        return;
      }
      const container = this.containerRef.current;
      const p = container.querySelector('p');
      if (!p) {
        return;
      }
      const lineHeight = parseInt(window.getComputedStyle(p, null).getPropertyValue("line-height"));
      let pList = Array.from(container.querySelectorAll('p'));
      let n = 0, lastP = null;
      container.innerHTML = '';
      while (Math.ceil(container.clientHeight / lineHeight) < maxRows && n < 100 && n < pList.length) {
        container.appendChild(pList[n]);
        lastP = pList[n];
        n++;
      }
      if (lastP && n < pList.length) {
        n = 0;
        lastP.textContent += '...';
        while (Math.ceil(container.clientHeight / lineHeight) > maxRows && n < 100) {
          lastP.textContent = lastP.textContent.substr(0, lastP.textContent.length - 4) + '...';
          n++;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {html, tagName: Tag, className} = this.props;
    return (
      <Tag
        ref={this.containerRef}
        className={className}
        dangerouslySetInnerHTML={{__html: sanitize(html)}}
        onClick={this.onClick}
      />
    );
  }
}

HTMLView.propTypes = {
  html: PropTypes.string,
  className: PropTypes.string,
  tagName: PropTypes.string,
  maxRows: PropTypes.number,
};

HTMLView.defaultProps = {
  className: 'html-view',
  tagName: 'div'
};

export default HTMLView;
