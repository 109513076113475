import React from 'react';
import {connect} from 'react-redux';
import {getCurrentUserAC} from '../redux/actions/user';

class CheckingUser extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(getCurrentUserAC(true));
  }

  render() {
    return null;
  }
}

export default connect()(CheckingUser);
