import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}/app`;

export const getAppVersionsAPI = (params = null) => {
  return Requester.get(`${API_URL}/versions`, params);
};

export const getLatestVersionAPI = (params = null) => {
  return Requester.get(`${API_URL}/versions/latest`, params);
};
