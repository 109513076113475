import qs from 'qs';
import moment from 'moment';
import slugify from 'slugify';
import Store from '../../redux/store/Store';

/**
 * Get query params from location.search
 * @param search
 * @param options
 * @return {object}
 */
export const getQueryParams = (search = undefined, options = {}) => {
  if (search === undefined) {
    search = document.location.search;
  }
  return qs.parse(search, {ignoreQueryPrefix: true, ...options});
};

/**
 * Buid query string
 * @param params
 * @param options
 * @return {string|*}
 */
export const buildQueryString = (params, options = {}) => {
  return qs.stringify(params, {options: true, ...options});
};

/**
 * Get error message
 * @param error
 * @returns {*}
 */
export const getErrorMessage = (error) => {
  if (error.response) {
    const {data, status} = error.response;
    if (data && data.metadata) {
      if (status === 400) {
        if (data.metadata.errors && Object.values(data.metadata.errors).length > 0) {
          return Object.values(data.metadata.errors)[0];
        }
      }
      if (data.metadata.message) {
        return data.metadata.message;
      }
    }
  }
  return error.message;
};

/**
 * Check user has permissions or not
 * @param requiredPermissions
 * @param userPermissions
 * @returns {boolean}
 */
export const hasPermissions = (requiredPermissions, userPermissions) => {
  if (!userPermissions) {
    const user = Store.getState().user.info;
    if (!user) {
      return false;
    }
    userPermissions = user.permissions || [];
  }
  if (!userPermissions.length) {
    return false;
  }
  let hasPermission = !requiredPermissions.length;
  if (userPermissions.indexOf(0) >= 0) {
    hasPermission = true;
  } else {
    requiredPermissions.forEach(permissionId => {
      if (userPermissions.indexOf(permissionId) >= 0) {
        hasPermission = true;
      }
    });
  }
  return hasPermission;
};

/**
 * Get total page
 * @param totalItems
 * @param pageSize
 * @returns {number}
 */
export const getTotalPage = (totalItems, pageSize) => {
  if (totalItems === 0 || pageSize === 0) {
    return 1;
  }
  return Math.ceil(totalItems / pageSize);
};

/**
 * Format date
 */
export const formatDate = (date, format = 'DD/MM/YYYY') => {
  if (!date) {
    return 'N/A';
  }
  return moment(date).format(format);
};

export const diffTime = (date, now = null) => {
  if (!date) {
    return 0;
  }
  const date1 = now ? moment(now).endOf('day') : moment();
  const date2 = moment(date);
  return date1.diff(date2);
};

/**
 * Random string
 * @returns {string}
 */
export const randomString = () => {
  return (
    Math.random()
    .toString(36)
    .substr(2, 16)
  );
};

export const stringToASCII = (str) => {
  if (!str || typeof str !== "string") {
    return 0;
  }
  let sum = 0;
  for(let i = 0; i < str.length; i++){
    let code = str.charCodeAt(i);
    sum += code;
  }
  return sum;
};

export const convertToSlug = (str = '', lower = true, replacement = '_') => {
  return slugify(str, {locale: 'vi', lower, replacement});
};

const S4 = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

export function generateGUID() {
  return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
}

export function getClipboardImageAsBase64(event, callback) {
  const items = event.clipboardData.items;
  const URLObj = window.URL || window.webkitURL;
  for (let i = 0; i < items.length; i++) {
    // Skip content if not image
    if (items[i].type.indexOf("image") === -1) continue;
    // Retrieve image on clipboard as blob

    event.preventDefault();

    const file = items[i].getAsFile();

    // Create an abstract canvas and get context
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext('2d');
    // Create an image
    const img = new Image();
    // Once the image loads, render the img on the canvas
    img.onload = function () {
      // Update dimensions of the canvas with the dimensions of the image
      canvas.width = this.width;
      canvas.height = this.height;

      // Draw the image
      ctx.drawImage(img, 0, 0);

      // Execute callback with the base64 URI of the image
      URLObj.revokeObjectURL(img.src);
      const a = canvas.toDataURL(
        (file.type || "image/png")
      );

      callback(a);
      img.remove();
    };
    img.src = URLObj.createObjectURL(file);
  }
}

export const convertTextToHTML = (str) => {
  return (str || "").replace(/(?:\r\n|\r|\n)/g, '<br>');
}
