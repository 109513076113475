import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Collapse, message, Spin } from 'antd';
import {getAppVersionsAPI} from '../../api/app';
import {getErrorMessage} from '../../common/helpers';

const {Panel} = Collapse;

class Download extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      versions: [],
      stableVersion: null,
    };
  }

  componentDidMount() {
    this.getVersions();
  }

  getVersions = () => {
    getAppVersionsAPI().then(res => {
      const versions = res?.data || [];
      const stableVersion = versions.find(item => item.latest && item.stable);
      this.setState({
        versions: res.data,
        stableVersion,
        isFetching: false,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {isFetching, versions, stableVersion} = this.state;
    return (
      <div className="download-app">
        <h1>Phần mềm cho Window PC</h1>
        {
          isFetching &&
          <Spin/>
        }
        {
          stableVersion &&
          <div className="stable-version">
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="large"
              className="download-button"
              href={stableVersion.download_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Tải phiên bản mới nhất ({stableVersion.size})
            </Button>
            <p className="text-center">Phiên bản hiện tại v{stableVersion.version}</p>
          </div>
        }
        {
          !!versions.length &&
          <Collapse className="all-versions">
            <Panel header="Tất cả các phiên bản" key="versions">
              <div className="versions">
                {
                  versions.map(item => (
                    <a
                      className="version-item"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.download_url}
                      key={item.version}
                    >
                      <DownloadOutlined /> Version {item.version} ({item.size})
                    </a>
                  ))
                }
              </div>
            </Panel>
          </Collapse>
        }
        <p>
          Cấu hình yêu cầu: Window 10 (32/64 bit), Ram 4GB.
        </p>
      </div>
    );
  }
}

Download.propTypes = {};

export default Download;
