import React from 'react';
import {connect} from 'react-redux';
import {registerAPI} from '../../../api/auth';
import { ContactsOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Alert, Button, Input, message } from 'antd';
import './Register.scss';
import {Link} from 'react-router-dom';
import {getErrorMessage} from '../../../common/helpers';
import {history} from '../../../history';

class Register extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isSubmitting: true,
        });
        this.handleRegister(values).then(() => {
          this.setState({
            isSubmitting: false,
          });
          message.success('Đăng ký thành công! Bạn có thể đăng nhập!');
          history.push('/login');
        }).catch(error => {
          message.error(getErrorMessage(error));
          this.setState({
            isSubmitting: false,
          });
        });
      }
    });
  };

  handleRegister = async (formData) => {
    const {data} = await registerAPI(formData);
    return data;
  };

  render() {
    const {form: {getFieldDecorator}, isOnline} = this.props;
    const {isSubmitting} = this.state;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {
          !isOnline &&
          <Alert
            type="error"
            description="Vui lòng kiểm tra kết nối internet của bạn"
            message="Không có mạng"
          />
        }
        <Form.Item label="Họ tên">
          {getFieldDecorator('name', {
            rules: [{
              required: true,
              message: 'Vui lòng nhập họ tên của bạn!'
            }],
          })(
            <Input
              prefix={<ContactsOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
              placeholder="Họ tên bạn"
            />,
          )}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [{
              required: true,
              message: 'Vui lòng nhập email của bạn!'
            }],
          })(
            <Input
              prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
              placeholder="Email của bạn"
            />,
          )}
        </Form.Item>
        <Form.Item label="Tên truy cập">
          {getFieldDecorator('username', {
            rules: [{
              required: true,
              message: 'Vui lòng nhập tên truy cập!'
            }],
          })(
            <Input
              prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
              placeholder="Tên truy cập hoặc email"
            />,
          )}
        </Form.Item>
        <Form.Item label="Mật khẩu">
          {getFieldDecorator('password', {
            rules: [{
              required: true,
              message: 'Vui lòng nhập mật khẩu!'
            }],
          })(
            <Input
              prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
              type="password"
              placeholder="Mật khẩu"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={isSubmitting}
          >
            Đăng ký
          </Button>
          Bạn đã có tài khoản? <Link to={'/login'}>Đăng nhập.</Link>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isOnline: state.app.isOnline,
});

export default connect(mapStateToProps)(Form.create({name: 'register_form'})(Register));
