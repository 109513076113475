import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getFilesAPI = (params = null) => {
  return Requester.get(`${API_URL}/files`, params);
};

export const uploadFilesAPI = (files, params = null) => {
  const formData = new FormData();
  if (typeof files === 'object') {
    if (Array.isArray(files)) {
      files.forEach(file => {
        formData.append('files[]', file);
      });
    } else {
      formData.append('files[]', files);
    }
  }
  return Requester.post(`${API_URL}/files`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
    params,
  });
};

export const deleteFilesAPI = (data) => {
  return Requester.delete(`${API_URL}/files`, {data});
};

export const deleteFileAPI = (fileId) => {
  return Requester.delete(`${API_URL}/files/${fileId}`);
};

export const getDownloadFileLink = (fileId) => {
  return `${API_URL}/files/download/${fileId}`;
};

export const getViewFileLink = (fileId) => {
  return `${API_URL}/files/view/${fileId}`;
};
