import React from 'react';
import PropTypes from 'prop-types';
import {NOTIFICATION_TYPES} from '../../../common/constants';
import {NotificationAssignTask, NotificationCommentInTask, NotificationUpdateTask} from './types';
import {Avatar, message} from 'antd';
import {updateNotificationsAPI} from '../../../api/notifications';
import {formatDate, getErrorMessage} from '../../../common/helpers';
import NotificationNewEvent from './types/NotificationNewEvent';
import NotificationNewTimeOff from './types/NotificationNewTimeOff';
import NotificationTimeOffApproved from './types/NotificationTimeOffApproved';
import NotificationEventReminder from './types/NotificationEventReminder';

const DefaultContent = ({item}) => (
  <span>
    {item.message || 'Unknown'}
  </span>
);

class NotificationItem extends React.PureComponent {
  markAsRead = (callback = null) => {
    const {notification, getList} = this.props;
    if (notification.status) {
      return;
    }
    updateNotificationsAPI(notification.id, {
      status: 1,
    }).then(() => {
      if (getList) {
        getList();
      }
      if (callback && typeof callback === 'function') {
        callback();
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {notification, showAvatar, showTime} = this.props;
    const props = {
      ...this.props,
      markAsRead: this.markAsRead,
    };
    let content;
    switch (notification.type_id) {
      case NOTIFICATION_TYPES.ASSIGN_TASK.id:
        content = <NotificationAssignTask {...props}/>;
        break;
      case NOTIFICATION_TYPES.UPDATE_TASK.id:
        content = <NotificationUpdateTask {...props}/>;
        break;
      case NOTIFICATION_TYPES.ADD_COMMENT_IN_TASK.id:
        content = <NotificationCommentInTask {...props} action="đăng"/>;
        break;
      case NOTIFICATION_TYPES.UPDATE_COMMENT_IN_TASK.id:
        content = <NotificationCommentInTask {...props} action="cập nhật"/>;
        break;
      case NOTIFICATION_TYPES.DELETE_COMMENT_IN_TASK.id:
        content = <NotificationCommentInTask {...props} action="xóa"/>;
        break;
      case NOTIFICATION_TYPES.NEW_TIME_OFF.id:
        content = <NotificationNewTimeOff {...props}/>;
        break;
      case NOTIFICATION_TYPES.TIME_OFF_APPROVED_OR_REJECTED.id:
        content = <NotificationTimeOffApproved {...props}/>;
        break;
      case NOTIFICATION_TYPES.NEW_EVENT.id:
        content = <NotificationNewEvent {...props}/>;
        break;
      case NOTIFICATION_TYPES.EVENT_REMINDER.id:
        content = <NotificationEventReminder {...props}/>;
        break;
      default:
        content = <DefaultContent item={notification}/>;
        break;
    }
    return (
      <div className={`notification-item ${notification.status === 0 ? 'unread' : ''}`}>
        {
          showAvatar &&
          <div className="--avatar">
            {
              notification?.params?.userName &&
              <Avatar>{`${notification.params.userName}`.charAt(0)}</Avatar>
            }
            {
              !notification?.params?.userName &&
              <Avatar/>
            }
          </div>
        }
        {content}
        {
          showTime &&
          <div className="--time">
            ({formatDate(notification.created_at, 'DD/MM/YYYY HH:mm:ss')})
          </div>
        }
      </div>
    );
  }
}

NotificationItem.propTypes = {
  notification: PropTypes.any,
  getList: PropTypes.func,
  showAvatar: PropTypes.bool,
  showTime: PropTypes.bool,
  showFullContent: PropTypes.bool,
};

export default NotificationItem;
