export const EVENT_TYPE = {
  CUSTOM: 1,
  BIRTHDAY: 2,
  ATTACHMENT: 3,
  TIME_OFF: 4,
};

export const EVENT_REPEAT_TYPE = {
  NO_REPEAT: 1,
  EVERY_WEEK: 2,
  EVERY_MONTH: 3,
  EVERY_QUARTER: 4,
  EVERY_YEAR: 5,
};
