import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const loginAPI = (data) => {
  return Requester.post(`${API_URL}/login`, data);
};

export const refreshTokenAPI = (data) => {
  return Requester.post(`${API_URL}/token/refresh`, data);
};

export const forgotPasswordAPI = (data) => {
  return Requester.post(`${API_URL}/forgot-password`, data);
};

export const resetPasswordAPI = (data) => {
  return Requester.post(`${API_URL}/reset-password`, data);
};

export const registerAPI = (data) => {
  return Requester.post(`${API_URL}/register`, data);
};

export const updatePasswordAPI = (data) => {
  return Requester.put(`${API_URL}/update-password`, data);
};
