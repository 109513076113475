import React, {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import {getCalendarEventsAPI} from '../../../api/events';
import HTMLViewer from '../../../common/components/HTMLViewer/HTMLViewer';
import {convertTextToHTML} from '../../../common/helpers';
import {CalendarOutlined, CloseOutlined} from '@ant-design/icons';
import './Reminder.scss';

const Reminder = () => {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);

  const getDataAsync = useCallback(async () => {
    let params = {
      from_datetime: moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
      to_datetime: moment().add(1, 'day').endOf('day').format("YYYY-MM-DD HH:mm:ss"),
    };
    const {data: events} = await getCalendarEventsAPI(params);
    return events;
  }, []);

  const getData = useCallback(() => {
    getDataAsync().then((events) => {
      setEvents(events);
      setOpen(events?.length > 0);
    }).catch(error => {
      console.error(error);
    });
  }, [getDataAsync]);

  const toggle = useCallback(() => {
    setOpen(v => !v);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div className={`reminder ${open ? 'open' : ''}`}>
        <div className="reminder-header">
          <h3>Nhắc nhở</h3>
          <CloseOutlined className="icon-close" onClick={toggle}/>
        </div>
        <div className="reminder-list">
          {
            events.map((item) => (
              <div className={`reminder-item`} key={item.id}>
                <div className="reminder-item-content">
                  <div className="reminder-item-title">{item.title}</div>
                  <div className="reminder-item-time">{item.from_datetime && moment(item.from_datetime).format("DD/MM/YYYY")}</div>
                  <HTMLViewer className="reminder-item-description" maxRows={2} html={convertTextToHTML(item.description)}/>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      {
        events.length > 0 && !open &&
        <div className="reminder-toggle" onClick={toggle}>
          <CalendarOutlined/>
        </div>
      }
    </>
  );
};

export default React.memo(Reminder);
