import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import HyperLink from '../../common/components/HyperLink/HyperLink';
import Broadcaster from '../../common/helpers/broadcaster';
import {BROADCAST_EVENTS} from '../../common/constants/broadcastEvents';

const {Search} = Input;

class SearchBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      keyword: '',
    };
    this.inputRef = React.createRef();
  }

  handleOpen = () => {
    this.setState({
      isOpen: true,
    }, () => {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    });
  };

  handleBlur = () => {
    const {keyword} = this.state;
    if (keyword) {
      return;
    }
    this.handleClose();
  };

  handleClose = () => {
    const {isOpen} = this.state;
    if (!isOpen) {
      return;
    }
    Broadcaster.broadcast(BROADCAST_EVENTS.SEARCH, '');
    this.setState({
      isOpen: false,
    });
  };

  onChange = (event) => {
    const value = event.target.value;
    this.setState({
      keyword: value,
    });
  };

  onSearch = (keyword) => {
    this.setState({
      keyword: keyword,
    });
    Broadcaster.broadcast(BROADCAST_EVENTS.SEARCH, keyword);
  };

  render() {
    const {isOpen} = this.state;
    if (isOpen) {
      return (
        <div className="search-box">
          <Search
            onBlur={this.handleBlur}
            onSearch={this.onSearch}
            onChange={this.onChange}
            ref={this.inputRef}
          />
        </div>
      )
    }
    return (
      <HyperLink className="header-icon" onClick={this.handleOpen}>
        <SearchOutlined />
      </HyperLink>
    );
  }
}

export default SearchBox;
