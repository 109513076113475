export const USER_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1
}

export const USER_ROLE = {
  NORMAL: {
    id: 1,
    name: 'Thành viên',
  },
  MANAGER: {
    id: 5,
    name: 'Quản lý',
  },
  DIRECTOR: {
    id: 9,
    name: 'Giám đốc',
  },
};
