export const OBJECT_TYPE = {
  USER_AVATAR: 1,
  GROUP_AVATAR: 2,
  TASK_ATTACHMENT: 3,
  COMMENT_ATTACHMENT: 4,
  INVOICE_ATTACHMENT: 5,
  NOTE_ATTACHMENT: 6,
  MEETING_MINUTE_ATTACHMENT: 7,
  DOCUMENT: 30,
  NOTE: 40,
  EVENT: 50,
  USER: 100,
  CUSTOMER: 200,
  TASK_STATUS: 301,
};
