const defaultConfig = {
  onPrinted: function () {
  },
  onPrintError: function () {
  },
};

export const HTMLPrinter = ((config) => {
  const bodyEl = document.getElementsByTagName('body')[0];
  const iframeId = `print-frame`;
  let printFrame = null;
  const {onPrinted, onPrintError} = {...defaultConfig, ...config};

  const print = (html, css) => {
    // If iframe already existed
    const existedFrame = document.getElementById(iframeId);
    if (existedFrame) {
      bodyEl.removeChild(existedFrame);
    }

    printFrame = document.createElement('IFRAME');
    printFrame.setAttribute('id', iframeId);
    printFrame.classList.add('print-frame');
    // Hide iframe
    printFrame.setAttribute('style', 'visibility: hidden; height: 0; width: 0; position: absolute;');
    // Create content
    const iframeTitle = 'Print';

    let srcDoc = `
      <html>
      <head>
      <title>${iframeTitle}</title>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width,maximum-scale=1.0">
      <link rel="stylesheet" href="${process.env.PUBLIC_URL}/assets/css/print/print.css">
      <link rel="stylesheet" href="${process.env.PUBLIC_URL}/assets/css/print/print-frame.css">
    `;
    if (css) {
      srcDoc += `
        <style id="custom-css">
        ${css}
        </style>
      `;
    }
    srcDoc += `
      </head>
      <body>
        ${html}
      </body>
      </html>`;

    printFrame.srcdoc = srcDoc;

    // Append printer frame to body
    bodyEl.appendChild(printFrame);

    // Get iframe element
    printFrame.onload = function () {
      // Get iframe element
      const iframeElement = document.getElementById(iframeId);

      if (!iframeElement) {
        onPrintError('No print frame found!');
        return;
      }

      // Firefox need a small delay before trigger print
      setTimeout(() => {
        iframeElement.contentWindow.print();
        onPrinted();
      }, 50);
    };
  };

  return {
    print,
  };
})();

export const renderInvoiceHTML = (data) => {
  return `
  <div class="invoice-print">
    <div class="--title">
      <h1>Biên bản bàn giao số ${data.no}</h1>
      <p>Ngày ${data.date}</p>
    </div>
    <div class="--content">
      <p>
        <label>${data.company_type === 'deliver' ? 'Bên giao' : 'Bên nhận'}:</label><br>${data.company_name}
      </p>
      <p>
        <label>Mã số thuế:</label><br>${data.tax_code}
      </p>
      <p>
        <label>Địa chỉ:</label><br>${data.address}
      </p>
      <p>
        <label>Email:</label><br>${data.email}
      </p>
      <p>
        <label>1. Hóa đơn đầu vào:</label><br>${data.other.inputInvoice}<br>
        <strong>Tổng số:</strong> ${data.other.inputInvoiceTotal}
      </p>
      <p>
        <label>2. Hóa đơn đầu ra:</label><br>${data.other.outputInvoice}<br>
        <strong>Tổng số:</strong> ${data.other.outputInvoiceTotal}
      </p>
      <p>
        <label>3. Hồ sơ ngân hàng:</label><br>${data.other.bankInfo}
      </p>
      <p>
        <label>4. Hồ sơ khác:</label><br>${data.other.otherInfo}
      </p>
    </div>
  </div>
  `;
};


export const renderMeetingMinuteHTML = (data) => {
  return `
  <div class="invoice-print">
    <div class="--title">
      <h1>Biên bản họp số ${data.no}</h1>
      <p>Ngày ${data.date}</p>
    </div>
    <div class="--content">
      <p>
        <label>${data.company_type === 'party_a' ? 'Bên A' : 'Bên B'}:</label><br>${data.company_name}
      </p>
      <p>
        <label>Mã số thuế:</label><br>${data.tax_code}
      </p>
      <p>
        <label>Địa chỉ:</label><br>${data.address}
      </p>
      <p>
        <label>Email:</label><br>${data.email}
      </p>
      <p>
        <label>Nội dung:</label><br>${data.content}<br>
      </p>
      <div>${data.content}</div>
    </div>
  </div>
  `;
};
