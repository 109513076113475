import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import moment from 'moment';

class NotificationNewTimeOff extends React.PureComponent {
  render() {
    const {notification: {message, params}, markAsRead} = this.props;
    if (!params) {
      return (
        <span>{message}</span>
      );
    }
    const date = params.fromDate ? moment(params.fromDate).format("DD/MM/YYYY") : "";
    return (
      <Link to={`/time-offs`} className="notification-item" onClick={markAsRead}>
        <strong>{params.createdBy?.name}</strong> đã gửi một đơn xin nghỉ phép ngày <strong>{date}</strong>.
      </Link>
    );
  }
}

NotificationNewTimeOff.propTypes = {
  notification: PropTypes.any,
  markAsRead: PropTypes.func,
};

export default NotificationNewTimeOff;
