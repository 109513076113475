import React from 'react';
import PropTypes from 'prop-types';
import {Tabs} from 'antd';
import NotificationList from './NotificationList';
import {Link} from 'react-router-dom';

const {TabPane} = Tabs;

class NotificationTabs extends React.PureComponent {
  render() {
    const {unread, all, total, totalUnread, getList} = this.props;
    return (
      <div className="notifications-dropdown">
        <Tabs className="notification-tabs" defaultActiveKey="unread" animated={false}>
          <TabPane tab={`Chưa đọc (${totalUnread})`} key="unread">
            <NotificationList notifications={unread} getList={getList} total={unread.length}/>
          </TabPane>
          <TabPane tab={`Tất cả (${total})`} key="all">
            <NotificationList notifications={all} total={total} getList={getList}/>
          </TabPane>
        </Tabs>
        <Link to="/notifications" className="show-more">
          Xem tất cả
        </Link>
      </div>
    );
  }
}

NotificationTabs.propTypes = {
  total: PropTypes.number,
  unread: PropTypes.array,
  all: PropTypes.array,
  getList: PropTypes.func,
};

export default NotificationTabs;
