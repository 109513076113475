export const NOTIFICATION_TYPES = {
  ASSIGN_TASK: {
    id: 1,
  },
  UPDATE_TASK: {
    id: 2,
  },
  ADD_COMMENT_IN_TASK: {
    id: 3,
  },
  UPDATE_COMMENT_IN_TASK: {
    id: 4,
  },
  DELETE_COMMENT_IN_TASK: {
    id: 5,
  },
  NEW_TIME_OFF: {
    id: 6,
  },
  TIME_OFF_APPROVED_OR_REJECTED: {
    id: 7,
  },
  NEW_EVENT: {
    id: 8,
  },
  EVENT_REMINDER: {
    id: 9,
  },
};
