import axios from 'axios';
import {onRequest, onRequestError, onResponse, onResponseError} from './DefaultImplementation';

const CACHE_TTL = 15 * 60 * 1000; // 15 minutes

class HTTPClient {
  constructor(options = {}) {
    this.axiosInstance = axios.create();
    this.options = {
      onRequest: onRequest,
      onRequestError: onRequestError,
      onResponse: onResponse,
      onResponseError: onResponseError,
      ...options,
    };
    this.axiosInstance.interceptors.request.use(this.options.onRequest, this.options.onRequestError);
    this.axiosInstance.interceptors.response.use(this.options.onResponse, this.options.onResponseError);
  }

  getCacheKey = (config) => {
    const objJsonStr = JSON.stringify(config);
    return 'cache_' + btoa(objJsonStr);
  };

  setCache = (config, response) => {
    if (!config || !response) {
      return false;
    }
    const saveObj = {
      expiration: new Date().getTime() + (config.__cacheTime || CACHE_TTL),
      response: response,
    };
    const cacheKey = this.getCacheKey(config);
    const saveData = JSON.stringify(saveObj);
    try {
      sessionStorage.setItem(cacheKey, saveData);
    } catch (error) {
      sessionStorage.clear();
    }
  };

  getCache = (config) => {
    let value = null;
    let obj = null;
    const cacheKey = this.getCacheKey(config);
    try {
      value = sessionStorage.getItem(cacheKey);
      if (!value) {
        return null;
      }
      obj = JSON.parse(value);
      const t = new Date().getTime();
      if (obj.expiration && obj.expiration < t) {
        sessionStorage.removeItem(cacheKey);
        return null;
      }
    } catch (e) {
      return null;
    }
    if (!obj) {
      return null;
    }
    return obj.response;
  };

  /**
   * Make a HTTP request
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  request = (config = {}) => {
    if (config.__cache) {
      const responseCached = this.getCache(config);
      if (responseCached) {
        return Promise.resolve(responseCached);
      }
    }
    return this.axiosInstance.request(config).then(response => {
      if (config.__cache && response?.metadata?.code === 'SUCCESS') {
        this.setCache(config, response);
      }
      return response;
    });
  };

  /**
   * Make GET request
   * @param url
   * @param params
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  get = (url, params = null, config = {}) => {
    return this.request({
      method: 'get',
      url,
      params,
      ...config,
    });
  };

  /**
   * Make POST request
   * @param url
   * @param data
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  post = (url, data = null, config = {}) => {
    return this.request({
      method: 'post',
      url,
      data,
      ...config,
    });
  };

  /**
   * Make PUT request
   * @param url
   * @param data
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  put = (url, data = null, config = {}) => {
    return this.request({
      method: 'put',
      url,
      data,
      ...config,
    });
  };

  /**
   * Make DELETE request
   * @param url
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  delete = (url, config = {}) => {
    return this.request({
      method: 'delete',
      url,
      ...config,
    });
  };
}

export default HTTPClient;
