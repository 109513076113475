import Requester from '../common/network/Requester';

const TASKS_API_URL = `${process.env.REACT_APP_API_URL}/tasks`;

export const getTasksAPI = (params) => {
  return Requester.get(`${TASKS_API_URL}`, params);
};

export const searchTasksAPI = (params) => {
  return Requester.get(`${TASKS_API_URL}/search`, params);
};

export const getTaskDetailAPI = (taskId) => {
  return Requester.get(`${TASKS_API_URL}/${taskId}`);
};

export const createTaskAPI = (data) => {
  return Requester.post(`${TASKS_API_URL}`, data);
};

export const updateTaskAPI = (taskId, data) => {
  return Requester.put(`${TASKS_API_URL}/${taskId}`, data);
};

export const updateTaskStatusAPI = (taskId, data) => {
  return Requester.put(`${TASKS_API_URL}/${taskId}/status`, data);
};

export const deleteTaskAPI = (taskId, data) => {
  return Requester.delete(`${TASKS_API_URL}/${taskId}`, data);
};

export const getTaskAssigneesAPI = (taskId) => {
  return Requester.get(`${TASKS_API_URL}/${taskId}/assignees`);
};

export const updateTaskAssigneesAPI = (taskId, data) => {
  return Requester.put(`${TASKS_API_URL}/${taskId}/assignees`, data);
};

export const getTasksDashBoardAPI = (params) => {
  return Requester.get(`${TASKS_API_URL}/dashboard`, params)
}
