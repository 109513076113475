import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

function extractContent(s) {
  const div = document.createElement('div');
  div.innerHTML = s;
  return div.textContent || div.innerText;
}

class NotificationCommentInTask extends React.PureComponent {
  render() {
    const {notification: {message, params}, markAsRead, action = 'đăng', showFullContent} = this.props;
    if (!params) {
      return (
        <span>{message}</span>
      );
    }
    return (
      <Link to={`/tasks/detail/${params.taskId}`} className="notification-item" onClick={markAsRead}>
        <strong>{params.userName}</strong> đã {action} một bình luận trong <strong>{params.taskName}</strong>{
          showFullContent &&
          `: ${extractContent(params.commentContent)}`
        }.
      </Link>
    );
  }
}

NotificationCommentInTask.propTypes = {
  notification: PropTypes.any,
  markAsRead: PropTypes.func,
  action: PropTypes.string,
};

export default NotificationCommentInTask;
