import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Routes from './Routes';
import {networkStatusChangedAC} from './redux/actions';
import packageJson from '../package.json';
import {getLatestVersionAPI} from './api/app';
import {Modal} from 'antd';
import NewVersion from './common/components/NewVersion/NewVersion';
import ImageLightBox from './common/components/ImageLightBox/ImageLightBox';
import BirthdayService from './pages/birthday/BirthdayService/BirthdayService';
import Reminder from './pages/calendar/Reminder/Reminder';

class App extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('online', this.networkStatusChanged);
    window.addEventListener('offline', this.networkStatusChanged);
    if (window.appAPI) {
      this.checkVersion();
    }
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        console.error(e);
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.networkStatusChanged);
    window.removeEventListener('offline', this.networkStatusChanged);
  }

  networkStatusChanged = () => {
    const {dispatch} = this.props;
    dispatch(networkStatusChangedAC(navigator.onLine));
  };

  checkVersion = () => {
    getLatestVersionAPI({current_version: packageJson.version}).then(res => {
      if (res?.data?.version && res.data.version !== packageJson.version) {
        if (!res.data.force_update) {
          Modal.info({
            title: `Cập nhật phần mềm`,
            content: <NewVersion data={res.data}/>,
            onOk: this.goToDownload(res.data.url),
          });
        } else {
          Modal.warning({
            title: `[Quan trọng] Cập nhật phần mềm`,
            content: <NewVersion data={res.data}/>,
            onOk: () => {
              this.goToDownload(res.data.url)();
              return Promise.reject('Could not close');
            },
            maskClosable: false,
            onCancel: () => {
              return Promise.reject('Could not close');
            },
          });
        }
      }
    }).catch(error => {

    });
  };

  goToDownload = (url) => () => {
    if (!url) {
      return;
    }
    if (window.appAPI?.openExternal) {
      window.appAPI.openExternal(url).catch(() => {
        Modal.error({
          title: `Lỗi: Không thể mở liên kết download`,
          content: `Vui lòng truy cập website để tải phiên bản mới nhất`,
        });
      });
    }
  };

  render() {
    const {isAuthenticated} = this.props;
    return (
      <Fragment>
        <Routes isAuthenticated={isAuthenticated}/>
        <ImageLightBox/>
        {
          isAuthenticated &&
          <>
            <BirthdayService/>
            <Reminder/>
          </>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.auth.isAuthenticated,
});

export default withRouter(connect(mapStateToProps)(App));
