export const PERMISSIONS = {
  ALL: {
    id: 0,
    name: 'Tất cả',
  },

  USERS_VIEW: {
    id: 10,
    name: 'Xem tất cả người dùng'
  },
  USERS_UPDATE: {
    id: 12,
    name: 'Chỉnh sửa người dùng',
    require: [10],
  },
  USERS_DELETE: {
    id: 13,
    name: 'Xóa người dùng',
    require: [10],
  },

  TASKS_VIEW: {
    id: 20,
    name: 'Xem tất cả công việc',
  },
  TASKS_CREATE: {
    id: 21,
    name: 'Tạo công việc',
  },
  TASKS_UPDATE: {
    id: 22,
    name: 'Chỉnh sửa công việc',
    require: [20],
  },
  TASKS_DELETE: {
    id: 23,
    name: 'Xóa công việc',
    require: [20],
  },
  TASKS_REPORT: {
    id: 24,
    name: 'Xem báo cáo',
    require: [20],
  },

  USER_GROUPS_VIEW: {
    id: 30,
    name: 'Xem tất cả nhóm',
  },
  USER_GROUPS_CREATE: {
    id: 31,
    name: 'Tạo nhóm',
    require: [30],
  },
  USER_GROUPS_UPDATE: {
    id: 32,
    name: 'Chỉnh sửa nhóm',
    require: [30],
  },
  USER_GROUPS_DELETE: {
    id: 33,
    name: 'Xóa nhóm',
    require: [30],
  },

  COMMENTS_VIEW: {
    id: 40,
    name: 'Xem tất cả bình luận',
  },
  COMMENTS_CREATE: {
    id: 41,
    name: 'Đăng bình luận',
    require: [40],
  },
  COMMENTS_UPDATE: {
    id: 42,
    name: 'Chỉnh sửa bình luận',
    require: [40],
  },
  COMMENTS_DELETE: {
    id: 43,
    name: 'Xóa bình luận',
    require: [40],
  },

  SETTINGS_VIEW: {
    id: 50,
    name: 'Xem thiết lập',
  },
  SETTINGS_UPDATE: {
    id: 52,
    name: 'Chỉnh sửa thiết lập',
    require: [50],
  },

  INVOICES_VIEW: {
    id: 60,
    name: 'Xem tất cả hóa đơn',
  },
  INVOICES_CREATE: {
    id: 61,
    name: 'Tạo hóa đơn',
    require: [60],
  },
  INVOICES_UPDATE: {
    id: 62,
    name: 'Chỉnh sửa hóa đơn',
    require: [60],
  },
  INVOICES_DELETE: {
    id: 63,
    name: 'Xóa hóa đơn',
    require: [60],
  },

  REPORTS_VIEW: {
    id: 90,
    name: 'Xem báo cáo',
  },
  REPORTS_CREATE: {
    id: 91,
    name: 'Tạo báo cáo',
    require: [90],
  },
  REPORTS_UPDATE: {
    id: 92,
    name: 'Chỉnh sửa báo cáo',
    require: [90],
  },
  REPORTS_DELETE: {
    id: 93,
    name: 'Xóa báo cáo',
    require: [90],
  },
  REPORTS_VIEW_ALL: {
    id: 94,
    name: 'Xem tất cả báo cáo',
  },

  CUSTOMER_VIEW: {
    id: 100,
    name: "Xem khách hàng",
  },
  CUSTOMER_CREATE: {
    id: 101,
    name: 'Tạo khách hàng',
    require: [100]
  },
  CUSTOMER_UPDATE: {
    id: 102,
    name: 'Chỉnh sửa khách hàng',
    require: [100]
  },
  CUSTOMER_DELETE: {
    id: 103,
    name: 'Xóa khách hàng',
    require: [100]
  },
  CUSTOMER_VIEW_ALL: {
    id: 104,
    name: "Xem tất cả khách hàng",
    require: [100]
  },

  CUSTOMER_NOTE_VIEW: {
    id: 110,
    name: "Xem ghi chú",
  },
  CUSTOMER_NOTE_CREATE: {
    id: 111,
    name: 'Tạo ghi chú',
    require: [110]
  },
  CUSTOMER_NOTE_UPDATE: {
    id: 112,
    name: 'Chỉnh sửa ghi chú',
    require: [110]
  },
  CUSTOMER_NOTE_DELETE: {
    id: 113,
    name: 'Xóa ghi chú',
    require: [110]
  },
  CUSTOMER_NOTE_VIEW_ALL: {
    id: 114,
    name: "Xem tất cả ghi chú",
  },

  MEETING_MINUTES_VIEW: {
    id: 120,
    name: 'Xem biên bản',
  },
  MEETING_MINUTES_CREATE: {
    id: 121,
    name: 'Tạo biên bản',
    require: [120],
  },
  MEETING_MINUTES_UPDATE: {
    id: 122,
    name: 'Chỉnh sửa biên bản',
    require: [120],
  },
  MEETING_MINUTES_DELETE: {
    id: 123,
    name: 'Xóa biên bản',
    require: [120],
  },
  MEETING_MINUTES_VIEW_ALL: {
    id: 124,
    name: 'Xem tất cả biên bản',
  },

  DOCUMENT_VIEW: {
    id: 130,
    name: 'Xem biểu mẫu',
  },
  DOCUMENT_CREATE: {
    id: 131,
    name: 'Tạo biểu mẫu',
    require: [130],
  },
  DOCUMENT_UPDATE: {
    id: 132,
    name: 'Chỉnh sửa biểu mẫu',
    require: [130],
  },
  DOCUMENT_DELETE: {
    id: 133,
    name: 'Xóa báo cáo',
    require: [130],
  },

  USER_DOCUMENTS_VIEW: {
    id: 140,
    name: 'Xem hồ sơ',
  },
  USER_DOCUMENTS_CREATE: {
    id: 141,
    name: 'Tạo hồ sơ',
    require: [140],
  },
  USER_DOCUMENTS_UPDATE: {
    id: 142,
    name: 'Chỉnh sửa hồ sơ',
    require: [140],
  },
  USER_DOCUMENTS_DELETE: {
    id: 143,
    name: 'Xóa hồ sơ',
    require: [140],
  },
  USER_DOCUMENTS_VIEW_ALL: {
    id: 144,
    name: 'Xem tất cả hồ sơ (của mọi người)',
  },

  APPROVE_TIME_OFF: {
    id: 150,
    name: 'Phê duyệt lịch nghỉ'
  },
  MANAGE_TIME_OFF: {
    id: 155,
    name: 'Quản lý nghỉ phép'
  },
};

export const PERMISSION_BY_ID = Object.values(PERMISSIONS).reduce((result, item) => {
  result[item.id] = item;
  return result;
}, {});

export const PERMISSION_GROUPS = [
  {
    name: 'Người dùng',
    permissions: [
      PERMISSIONS.USERS_VIEW,
      PERMISSIONS.USERS_UPDATE,
      PERMISSIONS.USERS_DELETE,
      PERMISSIONS.APPROVE_TIME_OFF,
      PERMISSIONS.MANAGE_TIME_OFF,
    ],
  },
  {
    name: 'Nhóm',
    permissions: [
      PERMISSIONS.USER_GROUPS_VIEW,
      PERMISSIONS.USER_GROUPS_CREATE,
      PERMISSIONS.USER_GROUPS_UPDATE,
      PERMISSIONS.USER_GROUPS_DELETE,
    ],
  },
  {
    name: 'Tài liệu',
    permissions: [
      PERMISSIONS.USER_DOCUMENTS_VIEW,
      PERMISSIONS.USER_DOCUMENTS_CREATE,
      PERMISSIONS.USER_DOCUMENTS_UPDATE,
      PERMISSIONS.USER_DOCUMENTS_DELETE,
    ],
  },
  {
    name: 'Khách hàng',
    permissions: [
      PERMISSIONS.CUSTOMER_VIEW,
      PERMISSIONS.CUSTOMER_CREATE,
      PERMISSIONS.CUSTOMER_UPDATE,
      PERMISSIONS.CUSTOMER_DELETE,
      PERMISSIONS.CUSTOMER_VIEW_ALL,
    ]
  },
  {
    name: 'Công việc',
    permissions: [
      PERMISSIONS.TASKS_VIEW,
      PERMISSIONS.TASKS_CREATE,
      PERMISSIONS.TASKS_UPDATE,
      PERMISSIONS.TASKS_DELETE,
      PERMISSIONS.TASKS_REPORT,
    ],
  },
  {
    name: 'Bình luận',
    permissions: [
      PERMISSIONS.COMMENTS_VIEW,
      PERMISSIONS.COMMENTS_CREATE,
      PERMISSIONS.COMMENTS_UPDATE,
      PERMISSIONS.COMMENTS_DELETE,
    ],
  },
  {
    name: 'Ghi chú',
    permissions: [
      PERMISSIONS.CUSTOMER_NOTE_VIEW,
      PERMISSIONS.CUSTOMER_NOTE_CREATE,
      PERMISSIONS.CUSTOMER_NOTE_UPDATE,
      PERMISSIONS.CUSTOMER_NOTE_DELETE,
      PERMISSIONS.CUSTOMER_NOTE_VIEW_ALL,
    ]
  },
  {
    name: 'Hóa đơn',
    permissions: [
      PERMISSIONS.INVOICES_VIEW,
      PERMISSIONS.INVOICES_CREATE,
      PERMISSIONS.INVOICES_UPDATE,
      PERMISSIONS.INVOICES_DELETE,
    ],
  },
  {
    name: 'Biên bản cuộc họp',
    permissions: [
      PERMISSIONS.MEETING_MINUTES_VIEW,
      PERMISSIONS.MEETING_MINUTES_CREATE,
      PERMISSIONS.MEETING_MINUTES_UPDATE,
      PERMISSIONS.MEETING_MINUTES_DELETE,
      PERMISSIONS.MEETING_MINUTES_VIEW_ALL,
    ],
  },
  // {
  //   name: 'Thiếp lập',
  //   permissions: [
  //     PERMISSIONS.SETTINGS_VIEW,
  //     PERMISSIONS.SETTINGS_UPDATE,
  //   ],
  // },
  {
    name: 'Báo cáo',
    permissions: [
      PERMISSIONS.REPORTS_VIEW,
      PERMISSIONS.REPORTS_CREATE,
      PERMISSIONS.REPORTS_UPDATE,
      PERMISSIONS.REPORTS_DELETE,
      PERMISSIONS.REPORTS_VIEW_ALL,
    ],
  },
  {
    name: 'Biểu mẫu',
    permissions: [
      PERMISSIONS.DOCUMENT_VIEW,
      PERMISSIONS.DOCUMENT_CREATE,
      PERMISSIONS.DOCUMENT_UPDATE,
      PERMISSIONS.DOCUMENT_DELETE,
    ],
  },
  {
    name: 'Hồ sơ người dùng',
    permissions: [
      PERMISSIONS.USER_DOCUMENTS_VIEW,
      PERMISSIONS.USER_DOCUMENTS_CREATE,
      PERMISSIONS.USER_DOCUMENTS_UPDATE,
      PERMISSIONS.USER_DOCUMENTS_DELETE,
      PERMISSIONS.USER_DOCUMENTS_VIEW_ALL,
    ],
  },
];
