import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {TIME_OFF_STATUS} from '../../../../common/constants/timeOff';

class NotificationTimeOffApproved extends React.PureComponent {
  render() {
    const {notification: {message, params}, markAsRead} = this.props;
    if (!params) {
      return (
        <span>{message}</span>
      );
    }
    const date = params.fromDate ? moment(params.fromDate).format("DD/MM/YYYY") : "";
    const status = params.status === TIME_OFF_STATUS.APPROVED ? 'được duyệt' : 'bị từ chối';
    return (
      <Link to={`/me/time-offs`} className="notification-item" onClick={markAsRead}>
        Đơn xin nghỉ phép ngày <strong>{date}</strong> của bạn đã {status}.
      </Link>
    );
  }
}

NotificationTimeOffApproved.propTypes = {
  notification: PropTypes.any,
  markAsRead: PropTypes.func,
};

export default NotificationTimeOffApproved;
