import React from 'react';
import Marquee from "react-fast-marquee";
import {getTasksAPI} from '../../api/tasks';
import {TASK_STATUS} from '../../common/constants/taskStatus';
import {formatDate} from '../../common/helpers';

class HeaderNotifications extends React.PureComponent {
  state = {
    tasks: [],
  };

  timer = null;

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const {user} = this.props;
    if (!user?.id) {
      this.setState({
        tasks: [],
      });
      return;
    }
    getTasksAPI({
      page_number: 1,
      page_size: 10,
      sort_order: 'desc',
      sort_by: 'deadline',
      status: `${TASK_STATUS.TODO.id},${TASK_STATUS.IN_PROGRESS.id}`,
      assignee_id: user.id,
    }).then(res => {
      if (res?.data?.page_data) {
        this.setState({
          tasks: res.data.page_data,
        });
      }
    }).catch(error => {
      this.setState({
        tasks: [],
      });
    });
  };

  render() {
    const {tasks} = this.state;
    return (
      <div className="header-notifications">
        {
          !!tasks.length &&
          <div className="marquee">
            <Marquee speed={35} gradient={false}>
              {
                tasks.map((item, index) => (
                  <div className="--item" key={index}><strong>({formatDate(item.deadline)})</strong> {item.name} </div>
                ))
              }
            </Marquee>
          </div>
        }
      </div>
    );
  }
}

export default HeaderNotifications;
