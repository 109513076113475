import {call, put, takeEvery} from 'redux-saga/effects';
import {GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS, getNotificationsSuccessAC} from '../../actions';
import {getNotificationsAPI} from '../../../api/notifications';
import {message} from 'antd';
import {getErrorMessage, getValueFromStorage, storeValueToStorage} from '../../../common/helpers';
import Store from '../../store/Store';
import {NOTIFICATION_TYPES} from '../../../common/constants';
import {history} from '../../../history';

function getNotification(notification) {
  const {message, params} = notification;
  if (!params) {
    return message;
  }
  let action = '';
  switch (notification.type_id) {
    case NOTIFICATION_TYPES.ASSIGN_TASK.id:
      return `${params.userName} đã giao một việc cho bạn: "${params.taskName}"`;
    case NOTIFICATION_TYPES.UPDATE_TASK.id:
      return `${params.userName} đã cập nhật công việc "${params.taskName}"`;
    case NOTIFICATION_TYPES.ADD_COMMENT_IN_TASK.id:
      action = 'đăng';
      return `${params.userName} đã ${action} một bình luận trong: "${params.taskName}"`;
    case NOTIFICATION_TYPES.UPDATE_COMMENT_IN_TASK.id:
      action = 'cập nhật';
      return `${params.userName} đã ${action} một bình luận trong: "${params.taskName}"`;
    case NOTIFICATION_TYPES.DELETE_COMMENT_IN_TASK.id:
      action = 'xóa';
      return `${params.userName} đã ${action} một bình luận trong: "${params.taskName}"`;
    default:
      return 'Bạn có thông báo mới';
  }
}

function* getNotificationsFlow() {
  try {
    const {data: unreadData} = yield call(getNotificationsAPI, {unread: true});
    const {data: allData} = yield call(getNotificationsAPI, {page_size: 5});
    yield put(getNotificationsSuccessAC({
      unread: unreadData?.page_data || [],
      totalUnread: unreadData?.page_info?.total_items || 0,
      all: allData?.page_data || [],
      total: allData?.page_info?.total_items || 0,
    }));
  } catch (e) {
    message.error(getErrorMessage(e));
  }
}

function getNotificationsSuccessFlow(action) {
  const {unread} = action.data;
  if (!unread.length) {
    return;
  }
  const sessionId = Store.getState()?.app?.sessionId || 'undefined';
  const notifications = getValueFromStorage('notifiedNotificationIds', {});
  let newNotifications = unread;
  const ids = {};
  if (notifications?.[sessionId]) {
    const notifiedIds = Object.values(notifications[sessionId]);
    newNotifications = unread.filter(item => !notifiedIds.includes(item.id));
    notifiedIds.forEach(id => {
      ids[id] = id;
    });
  }
  if (!newNotifications.length) {
    return;
  }
  if (window.appAPI?.onReceiveNewNotifications) {
    window.appAPI.onReceiveNewNotifications();
  }
  newNotifications.forEach(item => {
    ids[item.id] = item.id;
  });
  storeValueToStorage('notifiedNotificationIds', {
    [sessionId]: ids,
  });
  let message = `Bạn có ${newNotifications.length} thông báo mới`;
  if (newNotifications.length === 1) {
    message = getNotification(newNotifications[0]);
  }
  const nofity = new Notification(`Đông Dương Tax`, {
    body: message,
    requireInteraction: true,
  });
  nofity.onclick = function () {
    if (window.appAPI?.onClickNotification) {
      window.appAPI.onClickNotification();
    }
    history.push('/notifications');
  };
}

function* Watcher() {
  yield takeEvery(GET_NOTIFICATIONS, getNotificationsFlow);
  yield takeEvery(GET_NOTIFICATIONS_SUCCESS, getNotificationsSuccessFlow);
}

export default Watcher;
