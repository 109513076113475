import React, {Component} from 'react';
import PropTypes from 'prop-types';
import NotificationItem from './NotificationItem';
import {Empty} from 'antd';

class NotificationList extends Component {
  render() {
    const {notifications, getList, total} = this.props;
    if (!total) {
      return (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Không có thông báo"/>
      )
    }
    return (
      <div className="notification-list">
        {
          notifications.map((item) => (
            <NotificationItem notification={item} getList={getList} key={item.id}/>
          ))
        }
        {
          notifications.length < total &&
          <div className="notification-item has-more">
            Và {total - notifications.length} thông báo khác...
          </div>
        }
      </div>
    );
  }
}

NotificationList.propTypes = {
  total: PropTypes.number,
  notifications: PropTypes.array,
  getList: PropTypes.func,
};

export default NotificationList;
