import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Modal} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import "./BirthdayModal.scss";
import {stringToASCII} from '../../../common/helpers';

const DEFAULT_WIDTH = 900;
const DEFAULT_HEIGHT = 506;
const TOTAL_TEMPLATES = 2;

const BirthdayModal = React.forwardRef(({onClose}, ref) => {
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(DEFAULT_WIDTH);
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const [frameRef, setFrameRef] = useState(null);

  const dataRef = useRef({});

  const handleOpen = useCallback((data) => {
    const w = Math.min(window.innerWidth - 300, DEFAULT_WIDTH);
    const h = Math.round(w * DEFAULT_HEIGHT / DEFAULT_WIDTH);
    setWidth(w);
    setHeight(h);
    dataRef.current = data;
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(onClose);
  }, [onClose]);

  const template = (() => {
    if (dataRef.current?.template) {
      return `template-${((dataRef.current.template - 1) % TOTAL_TEMPLATES) + 1}`;
    }
    if (dataRef.current?.name) {
      const sum = stringToASCII(dataRef.current.name);
      return `template-${(sum % TOTAL_TEMPLATES) + 1}`;
    }
    return `template-1`;
  })();

  useEffect(() => {
    if (frameRef) {
      if (dataRef.current) {
        frameRef.addEventListener('load', () => {
          const data = dataRef.current;
          frameRef.contentWindow.postMessage({
            call: 'sendValue',
            value: {
              name: data.name,
              avatar: data.avatar,
              birthday_wish: data.birthday_wish,
            }
          }, '*');
        });
      }
    }
  }, [frameRef]);

  useImperativeHandle(ref, () => {
    return {
      handleOpen,
      handleClose,
    };
  }, [handleOpen, handleClose]);

  return (
    <Modal
      title="Vertically centered modal dialog"
      centered
      visible={open}
      width={width}
      className="birthday-modal"
      wrapClassName="birthday-modal-wrap"
      destroyOnClose={true}
      modalRender={() => (
        <div className="birthday-modal-content" style={{width, height, overflow: "hidden"}}>
          <div className="close-button" onClick={handleClose}><CloseOutlined/></div>
          <iframe ref={(el) => setFrameRef(el)} src={`${process.env.PUBLIC_URL}/birthday-cards/${template}/index.html`} width={width} height={height}/>
        </div>
      )}
    />
  );
});

export default BirthdayModal;
