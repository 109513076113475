import React from 'react';
import {connect} from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import {
  ApartmentOutlined,
  AreaChartOutlined,
  AuditOutlined,
  BellOutlined,
  CalendarOutlined,
  DeploymentUnitOutlined,
  FileZipOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import {Layout, Menu, Tag} from 'antd';
import {history} from '../../history';
import {logoutAC} from '../../redux/actions/user';
import {hasPermissions} from '../../common/helpers';
import {PERMISSIONS} from '../../common/constants';
import Notifications from './Notifications/Notifications';
import SearchBox from './SearchBox';
import LoggedInUser from './LoggedInUser';
import ExternalUrl from '../../common/components/HyperLink/ExternalUrl';
import HeaderNotifications from './HeaderNotifications';
import './MainLayout.scss';

const {Header, Sider, Content, Footer} = Layout;
const {SubMenu} = Menu;
const searchPages = [];

const allMenu = [
  {
    key: "/tasks",
    title: "Công việc",
    icon: AreaChartOutlined,
    children: [
      {
        key: "/tasks/me",
        title: "Công việc của tôi",
      },
      {
        key: "/tasks",
        title: "Tất cả công việc",
        permissions: [PERMISSIONS.TASKS_VIEW.id],
      },
      {
        key: "/tasks/new",
        title: "Tạo công việc",
        permissions: [PERMISSIONS.TASKS_CREATE.id],
      },
      {
        key: "/tasks/search",
        title: "Báo cáo chi tiết",
        permissions: [PERMISSIONS.TASKS_REPORT.id],
      },
      {
        key: "/customers/notes",
        title: "Ghi chú",
        permissions: [PERMISSIONS.CUSTOMER_NOTE_VIEW.id],
      }
    ]
  },
  {
    key: "/calendar",
    title: "Lịch làm việc",
    icon: CalendarOutlined,
    children: [
      {
        key: "/calendar",
        title: "Lịch tháng",
      },
      {
        key: "/events",
        title: "Sự kiện",
      },
      {
        key: "/me/time-offs",
        title: "Nghỉ phép",
      },
      {
        key: "/time-offs",
        title: "Phê duyệt nghỉ phép",
        permissions: [PERMISSIONS.APPROVE_TIME_OFF.id],
      },
      {
        key: "/time-offs/manage",
        title: "Quản lý nghỉ phép",
        permissions: [PERMISSIONS.MANAGE_TIME_OFF.id],
      }
    ]
  },
  {
    key: "/invoices",
    title: "Biên bản",
    icon: AuditOutlined,
    children: [
      {
        key: "/invoices",
        title: "Hóa đơn",
        permissions: [PERMISSIONS.INVOICES_VIEW.id],
      },
      {
        key: "/meeting-minutes",
        title: "Biên bản họp",
        permissions: [PERMISSIONS.MEETING_MINUTES_VIEW.id],
      },
      {
        key: "/reports",
        title: "Báo cáo",
        permissions: [PERMISSIONS.REPORTS_VIEW.id],
      },
    ]
  },
  {
    key: "/documents",
    title: "Tài liệu",
    icon: FileZipOutlined,
    children: [
      {
        key: "/documents",
        title: "Biểu mẫu",
        permissions: [PERMISSIONS.DOCUMENT_VIEW.id],
      },
      {
        key: "/me/documents",
        title: "Hồ sơ nhân sự",
      },
    ]
  },
  {
    key: "/manage",
    title: "Quản lý",
    icon: ApartmentOutlined,
    children: [
      {
        key: "/users",
        title: "Người dùng",
        permissions: [PERMISSIONS.USERS_VIEW.id],
      },
      {
        key: "/users/groups",
        title: "Nhóm",
        permissions: [PERMISSIONS.USER_GROUPS_VIEW.id],
      },
      {
        key: "/customers",
        title: "Khách hàng",
        icon: DeploymentUnitOutlined,
        permissions: [PERMISSIONS.CUSTOMER_VIEW.id],
      }
    ],
  },
  {
    key: "/setting",
    title: "Thiết lập",
    icon: SettingOutlined,
    children: [
      {
        key: "/settings/attributes",
        title: "Thuộc tính",
        permissions: [PERMISSIONS.ALL.id],
      },
      {
        key: "/settings",
        title: "Thiết lập",
        permissions: [PERMISSIONS.SETTINGS_VIEW.id],
      }
    ],
  },
  {
    key: "/notifications",
    title: "Thông báo",
    icon: BellOutlined,
  },
  {
    key: "/account",
    title: "Tài khoản",
    icon: UserOutlined,
    children: [
      {
        key: "/account/profile",
        title: "Thông tin tài khoản",
      },
      {
        key: "/account/password",
        title: "Đổi mật khẩu",
      }
    ],
  },
];

class MainLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      currentPath: '',
      userMenu: [],
    };
  }

  componentDidMount() {
    this.prepareMenu();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location !== this.props.location || prevState.userMenu !== this.state.userMenu) {
      this.getActivePath();
    }
  }

  prepareMenu = () => {
    const {user} = this.props;
    const userPermissions = user ? (user.permissions || []) : [];
    const userMenu = cloneDeep(allMenu);
    for (let i = userMenu.length - 1; i >= 0; i--) {
      const level1 = userMenu[i];
      if (!level1.permissions) {
        level1.permissions = [];
      }
      let hasLevel1Permission = false;
      if (level1.children) {
        for (let j = level1.children.length - 1; j >= 0; j--) {
          const level2 = level1.children[j];
          if (!level2.permissions) {
            level2.permissions = [];
          }
          let hasLevel2Permission = false;
          if (!level2.permissions.length) {
            hasLevel2Permission = true;
          } else {
            hasLevel2Permission = hasPermissions(level2.permissions, userPermissions);
          }
          if (hasLevel2Permission) {
            hasLevel1Permission = true;
          }
          level1.permissions = [...level1.permissions, ...level2.permissions];
          if (!hasLevel2Permission) {
            level1.children.splice(j, 1);
          }
        }
        level1.permissions = uniq(level1.permissions);
      }
      hasLevel1Permission = hasLevel1Permission || hasPermissions(level1.permissions, userPermissions);
      if (!hasLevel1Permission) {
        userMenu.splice(i, 1);
      }
    }
    this.setState({
      userMenu,
    });
  };

  getActivePath = () => {
    const {location: {pathname}} = this.props;
    let currentPath = pathname;
    const prefix = [
      '/reports',
      '/invoices',
    ];
    for (let i = 0; i < prefix.length; i++) {
      if (`${pathname}`.startsWith(prefix[i])) {
        currentPath = prefix[i];
      }
    }
    if (`${pathname}`.startsWith('/tasks') && !['/tasks/me', '/tasks/new', '/tasks/search'].includes(pathname)) {
      currentPath = '/tasks';
    }
    this.setState({
      currentPath,
    });
  };

  toggle = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  handleClick = (event) => {
    if (event.key && event.key !== '/logout') {
      history.push(event.key);
    }
  };

  handleLogout = () => {
    this.props.dispatch(logoutAC());
  };

  render() {
    const {children, isOnline, user} = this.props;
    const {currentPath, collapsed, userMenu} = this.state;
    return (
      <Layout className="main-layout">
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="main-sider"
        >
          <div className="logo">
            {collapsed ? 'ĐD' : 'Đông Dương Tax'}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[currentPath]}
            defaultOpenKeys={['tasks']}
            onClick={this.handleClick}
          >
            {
              userMenu.map((level1) => {
                const IconComponent = level1.icon;
                if (!level1.children?.length) {
                  return (
                    <Menu.Item key={level1.key}>
                      {
                        !!IconComponent && <IconComponent/>
                      }
                      <span>{level1.title}</span>
                    </Menu.Item>
                  );
                } else {
                  return (
                    <SubMenu
                      key={`${level1.key}#open`}
                      title={
                        <span>
                          {
                            !!IconComponent && <IconComponent/>
                          }
                          <span>{level1.title}</span>
                        </span>
                      }
                    >
                      {
                        level1.children.map((level2) => {
                          return (
                            <Menu.Item key={level2.key}>{level2.title}</Menu.Item>
                          );
                        })
                      }
                    </SubMenu>
                  );
                }
              })
            }
          </Menu>
        </Sider>
        <Layout className={`main-layout-container ${collapsed ? 'sider-collapsed' : ''}`}>
          <Header
            className="main-header"
            style={{
              background: '#fff',
              padding: 0
            }}>
            {
              collapsed ? <MenuUnfoldOutlined className="trigger" onClick={this.toggle}/> :
                <MenuFoldOutlined className="trigger" onClick={this.toggle}/>
            }
            <HeaderNotifications user={user}/>
            <div className="header-components">
              {
                searchPages.includes(currentPath) &&
                <SearchBox/>
              }
              {
                !isOnline &&
                <div className={`header-icon network-status ${isOnline ? '' : 'offline'}`}>
                  <Tag color="#ff0000"> <WifiOutlined/>Không có mạng</Tag>
                </div>
              }
              <Notifications/>
              <LoggedInUser user={user} handleLogout={this.handleLogout}/>
            </div>
          </Header>
          <Content
            style={{
              margin: '0 16px',
              minHeight: 280,
            }}
          >
            {children}
          </Content>
          <Footer className="main-footer">
            <p className="copy-right">
              Dong Duong Tax ©2020.
              Developed by <ExternalUrl href="https://vinaetech.com">VinaETech</ExternalUrl>.
            </p>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
  isOnline: state.app.isOnline,
});

export default connect(mapStateToProps)(MainLayout);
