export const CUSTOMER_STATUS = {
  ACTIVE: {
    id: 1,
    name: 'Hoạt động',
    className: 'text-green',
  },
  TEMPORARY: {
    id: 2,
    name: 'Dịch vụ',
    className: 'text-blue',
  },
  INACTIVE: {
    id: 3,
    name: 'Tạm ngừng dịch vụ',
    className: 'text-gray',
  },
};
