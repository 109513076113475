import React, {Fragment} from 'react';
import {Breadcrumb, List, message, Spin} from 'antd';
import {getNotificationsAPI} from '../../api/notifications';
import {getErrorMessage} from '../../common/helpers';
import NotificationItem from '../../layout/MainLayout/Notifications/NotificationItem';
import HyperLink from '../../common/components/HyperLink/HyperLink';

class Notifications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageData: [],
      pagination: {
        page_number: 1,
        page_size: 10,
        total_items: 0,
      },
      isLoading: false,
      hasMore: true,
    };
  }

  componentDidMount() {
    this.handleGetData();
  }

  handleGetData = (params = {}) => {
    this.setState({
      isLoading: true,
    });
    getNotificationsAPI(params).then(res => {
      const {page_data, page_info} = res.data;
      const {page_number, page_size, total_items} = page_info;
      const hasMore = page_number * page_size < total_items;
      this.setState(prevState => {
        const pageData = prevState.pageData;
        pageData[page_number - 1] = page_data;
        let data = [];
        pageData.forEach(page => {
          data = [...data, ...page];
        });
        return {
          isLoading: false,
          hasMore,
          data,
          pageData,
          pagination: {
            ...prevState.pagination,
            ...page_info,
          },
        }
      });
    }).catch(error => {
      this.setState({
        isLoading: false,
      });
      message.error(getErrorMessage(error));
    });
  };

  handleLoadMore = () => {
    this.handleGetData({
      page_number: this.state.pagination.page_number + 1,
    });
  };

  render() {
    const {data, isLoading, hasMore} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Thông báo</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          <List
            dataSource={data}
            renderItem={item => (
              <List.Item key={item.id} style={{padding: 0}}>
                <NotificationItem
                  notification={item}
                  showAvatar={true}
                  showTime={true}
                  showFullContent={true}
                />
              </List.Item>
            )}
          >
            {isLoading && hasMore && (
              <div className="demo-loading-container">
                <Spin/>
              </div>
            )}
          </List>
          {
            hasMore &&
            <HyperLink className="show-more" onClick={this.handleLoadMore}>+ Xem thêm</HyperLink>
          }
        </div>
      </Fragment>
    );
  }
}

export default Notifications;
