import {getUsersAPI} from '../../api/user';
import {getCustomersAPI} from '../../api/customer';
import {OBJECT_TYPE} from '../constants/objectType';

function whichChild(elem) {
  let i = 0;
  while ((elem = elem.previousSibling) != null) ++i;
  return i;
}

function getColumnName() {
  const node = document.getSelection().anchorNode;
  const el = (node.nodeType === 3 ? node.parentNode : node);
  if (!el || !el.closest) {
    return null;
  }
  const tdEl = el.closest('td');
  if (!tdEl) {
    return null;
  }
  const colIndex = whichChild(tdEl);
  const tableEl = tdEl.closest('table');
  if (!tableEl) {
    return null;
  }
  const trEls = tableEl.getElementsByTagName('tr');
  if (!trEls.length) {
    return null;
  }
  let thEls = trEls[0].getElementsByTagName('th');
  if (!trEls.length) {
    thEls = trEls[0].getElementsByTagName('td');
  }
  if (!thEls.length || !thEls[colIndex]) {
    return null;
  }
  return thEls[colIndex].innerText;
}

function getProcessStatus() {
  return Promise.resolve({
    data: [
      {
        name: 'Hoàn thành',
      },
      {
        name: 'Chưa hoàn thành',
      },
      {
        name: 'Đang treo',
      },
      {
        name: 'Chưa thực hiện',
      }
    ],
  });
}

function getHandler() {
  let columnName = getColumnName();
  if (!columnName) {
    return [getUsersAPI, OBJECT_TYPE.USER];
  }
  columnName = columnName.toLowerCase();
  if (columnName.includes('công ty')) {
    return [getCustomersAPI, OBJECT_TYPE.CUSTOMER];
  }
  if (columnName.includes('tiến độ')) {
    return [getProcessStatus, OBJECT_TYPE.TASK_STATUS];
  }
  return [getUsersAPI, OBJECT_TYPE.USER];
}

function init() {
  const autoCompleter = {};
  autoCompleter.fetch = function (pattern) {
    if (!pattern || !pattern.trim()) {
      return [];
    }

    const [handler, type] = getHandler();

    if (!handler) {
      return Promise.resolve([]);
    }

    return Promise.resolve(handler({
      search: pattern,
    })).then(function (res) {
      let results = [];
      if (res && res.data) {
        if (res.data.page_data) {
          results = res.data.page_data;
        } else if (Array.isArray(res.data)) {
          results = res.data;
        }
      }
      results = results.map(function (item) {
        return {
          value: JSON.stringify({...item, type}),
          text: item.name,
        };
      });
      // sort results by the "name"
      results.sort(function (a, b) {
        let x = a.text.toLowerCase();
        let y = b.text.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
      return results;
    }).catch(function () {
      return [];
    });
  };

  autoCompleter.onAction = function (editor, autocompleteApi, rng, value) {
    if (value) {
      try {
        value = JSON.parse(value);
        if (value.name) {
          value = `<span class="auto-complete-tag" data-object-type="${value.type}" data-object-id="${value.id}">${value.name}</span> `;
        }
      } catch (error) {

      }
    }
    // insert in to the editor
    editor.selection.setRng(rng);
    editor.insertContent(value);
    // hide the autocompleter
    autocompleteApi.hide();
  };

  window.autocompleter = autoCompleter;
}

init();
