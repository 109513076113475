import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getCurrentUserAPI = () => {
  return Requester.get(`${API_URL}/users/current`);
};

export const getUsersAPI = (params = null) => {
  return Requester.get(`${API_URL}/users`, params);
};

export const updateUserAPI = (id, data) => {
  return Requester.put(`${API_URL}/users/${id}`, data);
};

export const deleteUserAPI = (id) => {
  return Requester.delete(`${API_URL}/users/${id}`);
};

export const getUsersByIdsAPI = (userIds) => {
  return Requester.post(`${API_URL}/users/multiple`, userIds, {
    __cache: true,
  });
};

export const getBirthdaysAPI = () => {
  return Requester.get(`${API_URL}/users/birthdays`);
};

export const getUserGroupsAPI = (params = null) => {
  return Requester.get(`${API_URL}/user-groups`, params);
};

export const getUserGroupDetailAPI = (id, params = null) => {
  return Requester.get(`${API_URL}/user-groups/${id}`, params);
};

export const getUserGroupsByIdsAPI = (groupIds) => {
  return Requester.post(`${API_URL}/user-groups/multiple`, groupIds, {
    __cache: true,
  });
};

export const createUserGroupAPI = (data) => {
  return Requester.post(`${API_URL}/user-groups`, data);
};

export const updateUserGroupAPI = (id, data) => {
  return Requester.put(`${API_URL}/user-groups/${id}`, data);
};

export const deleteUserGroupAPI = (id) => {
  return Requester.delete(`${API_URL}/user-groups/${id}`);
};

export const getUserDocumentsAPI = (params, userId) => {
  return Requester.get(`${API_URL}/users/${userId}/documents`, params);
};

export const getAllUserDocumentsAPI = (params = null) => {
  return Requester.get(`${API_URL}/users/documents`, params);
};

export const getUserDocumentDetailAPI = (userId, documentId) => {
  return Requester.get(`${API_URL}/users/${userId}/documents/${documentId}`);
};

export const createUserDocumentAPI = (userId, data) => {
  return Requester.post(`${API_URL}/users/${userId}/documents`, data);
};

export const updateUserDocumentAPI = (userId, documentId, data) => {
  return Requester.put(`${API_URL}/users/${userId}/documents/${documentId}`, data);
};

export const deleteUserDocumentAPI = (userId, documentId) => {
  return Requester.delete(`${API_URL}/users/${userId}/documents/${documentId}`);
};
