import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getCalendarEventsAPI = (params = null) => {
  return Requester.get(`${API_URL}/calendar`, params);
};

export const getCalendarTimeOffsAPI = (params = null) => {
  return Requester.get(`${API_URL}/calendar/time-offs`, params);
};

export const getUserEventsAPI = (userId, params = null) => {
  return Requester.get(`${API_URL}/users/${userId}/events`, params);
};

export const getUserEventDetailAPI = (userId, eventId) => {
  return Requester.get(`${API_URL}/users/${userId}/events/${eventId}`);
};

export const createUserEventAPI = (userId, data) => {
  return Requester.post(`${API_URL}/users/${userId}/events`, data);
};

export const updateUserEventAPI = (userId, eventId, data) => {
  return Requester.put(`${API_URL}/users/${userId}/events/${eventId}`, data);
};

export const deleteUserEventAPI = (userId, eventId) => {
  return Requester.delete(`${API_URL}/users/${userId}/events/${eventId}`);
};
