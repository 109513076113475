import React from 'react';
import {Switch} from 'react-router-dom';
import {AppRoute, MainLayout} from './layout';
import PublicLayout from './layout/PublicLayout/PublicLayout';
import Download from './pages/app/Download';
import Login from './pages/auth/Login/Login';
import Register from './pages/auth/Register/Register';
import ResetPassword from './pages/auth/ResetPassword/ResetPassword';
import Dashboard from './pages/dashboard/Dashboard';
import Notifications from './pages/notifications/Notifications';
import PageLoading from './common/components/Loading/PageLoading';

const SearchTasks = React.lazy(() => import('./pages/tasks/Search/SearchTasks'));
const CustomAttributes = React.lazy(() => import('./pages/settings/CustomAttributes/CustomAttributes'));
const ManageTimeOffs = React.lazy(() => import('./pages/timeOffs/ManageTimeOffs'));
const UserTimeOffs = React.lazy(() => import('./pages/userTimeOffs/UserTimeOffs'));
const TimeOffs = React.lazy(() => import('./pages/timeOffs/TimeOffs'));
const Tasks = React.lazy(() => import('./pages/tasks/Tasks'));
const TaskDetail = React.lazy(() => import('./pages/tasks/TaskDetail/TaskDetail'));
const EditTask = React.lazy(() => import('./pages/tasks/EditTask/EditTask'));
const CreateTask = React.lazy(() => import('./pages/tasks/CreateTask/CreateTask'));
const MyTasks = React.lazy(() => import('./pages/tasks/MyTasks/MyTasks'));
const Groups = React.lazy(() => import('./pages/users/Groups/Groups'));
const Users = React.lazy(() => import('./pages/users/Users/Users'));
const Customers = React.lazy(() => import("./pages/customers/Customers/Customers"));
const Reports = React.lazy(() => import('./pages/reports/Reports/Reports'));
const ReportDetail = React.lazy(() => import('./pages/reports/Reports/ReportDetail'));
const UpsertReport = React.lazy(() => import('./pages/reports/UpsertReport/UpsertReport'));
const Settings = React.lazy(() => import('./pages/settings/Settings/Settings'));
const Calendar = React.lazy(() => import('./pages/calendar/Calendar'));
const Events = React.lazy(() => import('./pages/events/Events'));
const MeetingMinutes = React.lazy(() => import('./pages/meetingMinutes/MeetingMinutes/MeetingMinutes'));
const Documents = React.lazy(() => import('./pages/documents/Documents/Documents'));
const UserDocuments = React.lazy(() => import('./pages/account/documents/Documents/UserDocuments'));
const CustomerNotes = React.lazy(() => import('./pages/customer-notes/CustomerNotes/CustomerNotes'));
const UpsertNote = React.lazy(() => import('./pages/customer-notes/UpsertNote/UpsertNote'));
const Invoices = React.lazy(() => import('./pages/invoices/Invoices/Invoices'));
const UpsertInvoice = React.lazy(() => import('./pages/invoices/UpsertInvoice/UpsertInvoice'));
const UpsertDocument = React.lazy(() => import('./pages/documents/UpsertDocument/UpsertDocument'));
const UpsertUserDocument = React.lazy(() => import('./pages/account/documents/UpsertDocument/UpsertUserDocument'));
const UpsertMeetingMinute = React.lazy(() => import('./pages/meetingMinutes/UpsertMeetingMinute/UpsertMeetingMinute'));
const AccountPassword = React.lazy(() => import('./pages/account/AccountPassword'));
const AccountProfile = React.lazy(() => import('./pages/account/AccountProfile'));

const Fallback = <PageLoading isLoading={true}/>;

const Routes = () => (
  <Switch>
    <AppRoute
      path={'/'}
      component={Dashboard}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/calendar'}
      component={Calendar}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/events'}
      component={Events}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/me/time-offs'}
      component={UserTimeOffs}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/time-offs/manage'}
      component={ManageTimeOffs}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/time-offs'}
      component={TimeOffs}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/download'}
      component={Download}
      layout={PublicLayout}
      exact={true}
    />
    <AppRoute
      path={'/tasks/me'}
      component={MyTasks}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/tasks'}
      component={Tasks}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/tasks/detail/:id'}
      component={TaskDetail}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/tasks/edit/:id'}
      component={EditTask}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/tasks/new'}
      component={CreateTask}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/tasks/search'}
      component={SearchTasks}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/customers/notes'}
      component={CustomerNotes}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={['/customers/notes/new', '/customers/notes/:id/edit']}
      component={UpsertNote}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/customers'}
      component={Customers}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/users'}
      component={Users}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/users/groups'}
      component={Groups}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/invoices'}
      component={Invoices}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/invoices/edit/:id'}
      component={UpsertInvoice}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/invoices/new'}
      component={UpsertInvoice}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/meeting-minutes'}
      component={MeetingMinutes}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/meeting-minutes/edit/:id'}
      component={UpsertMeetingMinute}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/meeting-minutes/new'}
      component={UpsertMeetingMinute}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/reports'}
      component={Reports}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/reports/new'}
      component={UpsertReport}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/reports/:id'}
      component={ReportDetail}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/reports/:id/edit'}
      component={UpsertReport}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/reports/:id/copy'}
      component={UpsertReport}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/settings'}
      component={Settings}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/settings/attributes'}
      component={CustomAttributes}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/account/profile'}
      component={AccountProfile}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/me/documents'}
      component={UserDocuments}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/me/documents/new'}
      component={UpsertUserDocument}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/me/documents/:id/edit'}
      component={UpsertUserDocument}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/account/password'}
      component={AccountPassword}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/notifications'}
      component={Notifications}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/login'}
      component={Login}
      layout={PublicLayout}
      exact={true}
    />
    <AppRoute
      path={'/register'}
      component={Register}
      layout={PublicLayout}
      exact={true}
    />
    <AppRoute
      path={'/reset-password'}
      component={ResetPassword}
      layout={PublicLayout}
      exact={true}
    />
    <AppRoute
      path={'/documents'}
      component={Documents}
      fallback={Fallback}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/documents/new'}
      component={UpsertDocument}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/documents/:id/edit'}
      component={UpsertDocument}
      layout={MainLayout}
      fallback={Fallback}
      isPrivate={true}
      exact={true}
    />
  </Switch>
);

export default Routes;
