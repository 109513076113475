import React from 'react';

const ExternalUrl = ({href, onClick, children, ...others}) => {
  const handleClick = (event) => {
    if (!window.appAPI?.openExternal) {
      return true;
    }
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }
    window.appAPI.openExternal(href).catch(() => {
    });
  };

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      {...others}
    >
      {children}
    </a>
  )
};

export default ExternalUrl;
