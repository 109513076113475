import React from 'react';
import showdown from 'showdown';
import sanitizeHtml from 'sanitize-html';
import './NewVersion.scss';

const converter = new showdown.Converter();

const NewVersion = React.memo(({data}) => {
  if (!data) {
    return null;
  }
  const html = sanitizeHtml(converter.makeHtml(data.changes || ''));
  return (
    <div className="new-version">
      <p>Phần mềm đã có phiên bản mới: <strong>{data.version}</strong>, vui lòng cập nhật để có trải nghiệm tốt nhất!</p>
      <div className="changes" dangerouslySetInnerHTML={{__html: html}}/>
    </div>
  )
});

export default NewVersion;
