/* eslint-disable no-multi-str */

export const DefaultConfig = {
  height: 500,
  menubar: false,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
    'hochtautocompleter',
  ],
  toolbar:
    'undo redo | formatselect | bold italic backcolor | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | removeformat | help'
};

export const InlineConfig = {
  inline: true,
  height: 350,
  menubar: false,
  plugins: [
    'insertdatetime table code help contextmenu',
    'hochtautocompleter',
  ],
  toolbar: 'bold italic underline | alignleft aligncenter alignright alignjustify | table | insertdatetime',
  insertdatetime_formats: ['%H:%M:%S', '%d/%m/%Y', '%H:%M:%S %d/%m/%Y'],
};

export const TaskDescriptionConfig = {
  ...InlineConfig,
  height: 350,
};

export const TaskCommentConfig = {
  ...InlineConfig,
  height: 150,
  // table_column_resizing: 'preservetable',
  table_resize_bars: false,
  table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow',
  table_sizing_mode: 'fixed',
  contextmenu: ""

};

export const InputInvoiceConfig = {
  ...InlineConfig,
  height: 250,
};

export const InputNoteConfig = {
  ...InlineConfig,
  height: 350,
};
