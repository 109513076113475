export const InitialTaskDescriptionTable = '' +
  '<table class="task-description-table" style="border-collapse: collapse; width: 100%; height: 42px;" border="1">' +
  '<thead>' +
  '<tr style="height: 21px;">' +
  '<th style="width: 19.1001%; height: 21px;">T&ecirc;n c&ocirc;ng ty</th>' +
  '<th style="width: 19.1001%; height: 21px;">Nội dung</th>' +
  '<th style="width: 19.1001%; height: 21px;">T&ecirc;n nh&acirc;n vi&ecirc;n</th>' +
  '<th style="width: 19.1626%; height: 21px;">Ng&agrave;y ho&agrave;n th&agrave;nh</th>' +
  '<th style="width: 19.1626%; height: 21px;">Ghi ch&uacute;</th>' +
  '</tr>' +
  '</thead>' +
  '<tbody>' +
  '<tr style="height: 21px;">' +
  '<td style="width: 19.1001%; height: 21px;">&nbsp;</td>' +
  '<td style="width: 19.1001%; height: 21px;">&nbsp;</td>' +
  '<td style="width: 19.1001%; height: 21px;">&nbsp;</td>' +
  '<td style="width: 19.1626%; height: 21px;">&nbsp;</td>' +
  '<td style="width: 19.1626%; height: 21px;">&nbsp;</td>' +
  '</tr>' +
  '</tbody>' +
  '</table>' +
  '<p>&nbsp;</p>';

export const InitialTaskCommentTable = '<table style="border-collapse: collapse; width: 98.4797%;" border="1">\n' +
  '<thead>\n' +
  '<tr>\n' +
  '<th style="width: 10.4132%;">Ngày</th>\n' +
  '<th style="width: 16.4132%;">T&ecirc;n c&ocirc;ng ty</th>\n' +
  '<th style="width: 26.4132%;">Nội dung thực hiện</th>\n' +
  '<th style="width: 14.4132%;">Tiến độ hoàn thành</th>\n' +
  '<th style="width: 18.4132%;">Ch&uacute; &yacute;</th>\n' +
  '<th style="width: 12.4132%;">Đ&aacute;nh gi&aacute;</th>\n' +
  '</tr>\n' +
  '</thead>\n' +
  '<tbody>\n' +
  '<tr>\n' +
  '<td style="width: 10.4132%;">&nbsp;</td>\n' +
  '<td style="width: 16.4132%;">&nbsp;</td>\n' +
  '<td style="width: 26.4132%;">&nbsp;</td>\n' +
  '<td style="width: 14.4132%;">&nbsp;</td>\n' +
  '<td style="width: 18.4132%;">&nbsp;</td>\n' +
  '<td style="width: 12.4132%;">&nbsp;</td>\n' +
  '</tr>\n' +
  '</tbody>\n' +
  '</table>';

export const InitialInvoiceInputTable = '<table style="border-collapse: collapse; width: 98.4797%; height: 68px;" border="1">\n' +
  '<thead>\n' +
  '<tr style="height: 34px;">\n' +
  '<th style="width: 24.9571%; height: 34px;">Năm/Qu&yacute;</th>\n' +
  '<th style="width: 24.9571%; height: 34px;">Số lượng</th>\n' +
  '<th style="width: 24.9571%; height: 34px;">T&igrave;nh trạng</th>\n' +
  '<th style="width: 25.0429%; height: 34px;">Ghi ch&uacute;</th>\n' +
  '</tr>\n' +
  '</thead>\n' +
  '<tbody>\n' +
  '<tr style="height: 34px;">\n' +
  '<td style="width: 24.9571%; height: 34px;">&nbsp;</td>\n' +
  '<td style="width: 24.9571%; height: 34px;">&nbsp;</td>\n' +
  '<td style="width: 24.9571%; height: 34px;">&nbsp;</td>\n' +
  '<td style="width: 25.0429%; height: 34px;">&nbsp;</td>\n' +
  '</tr>\n' +
  '</tbody>\n' +
  '</table>';

export const InitialBankInputTable = '<table style="border-collapse: collapse; width: 98.4797%;" border="1">\n' +
  '<thead>\n' +
  '<tr>\n' +
  '<th style="width: 11.4493%;">Năm/Qu&yacute;</th>\n' +
  '<th style="width: 17.0241%;">T&ecirc;n ng&acirc;n h&agrave;ng</th>\n' +
  '<th style="width: 14.2367%;">Sổ phụ ng&acirc;n h&agrave;ng</th>\n' +
  '<th style="width: 14.2367%;">Ủy nhiệm chi</th>\n' +
  '<th style="width: 14.3225%;">Giấy b&aacute;o nợ/c&oacute;</th>\n' +
  '<th style="width: 14.3225%;">Hồ sơ vay</th>\n' +
  '<th style="width: 14.3225%;">Hồ sơ kh&aacute;c</th>\n' +
  '</tr>\n' +
  '</thead>\n' +
  '<tbody>\n' +
  '<tr>\n' +
  '<td style="width: 11.4493%;">&nbsp;</td>\n' +
  '<td style="width: 17.0241%;">&nbsp;</td>\n' +
  '<td style="width: 14.2367%;">&nbsp;</td>\n' +
  '<td style="width: 14.2367%;">&nbsp;</td>\n' +
  '<td style="width: 14.3225%;">&nbsp;</td>\n' +
  '<td style="width: 14.3225%;">&nbsp;</td>\n' +
  '<td style="width: 14.3225%;">&nbsp;</td>\n' +
  '</tr>\n' +
  '</tbody>\n' +
  '</table>';

export const InitialReportTable = '<table style="border-collapse: collapse; width: 98.4797%;" border="1">\n' +
  '<thead>\n' +
  '<tr>\n' +
  '<th style="width: 6.6583%;" scope="col">STT</th>\n' +
  '<th style="width: 21.8151%;" scope="col">T&ecirc;n c&ocirc;ng ty</th>\n' +
  '<th style="width: 14.2367%;" scope="col">B&aacute;o c&aacute;o thsd h&oacute;a đơn</th>\n' +
  '<th style="width: 14.2367%;" scope="col">01/GTGT</th>\n' +
  '<th style="width: 14.3225%;" scope="col">Nộp thuế</th>\n' +
  '<th style="width: 14.3225%;" scope="col">Ng&agrave;y nộp</th>\n' +
  '<th style="width: 14.3225%;" scope="col">Ghi ch&uacute;</th>\n' +
  '</tr>\n' +
  '</thead>\n' +
  '<tbody>\n' +
  '<tr>\n' +
  '<td style="width: 6.6583%;">&nbsp;</td>\n' +
  '<td style="width: 21.8151%;">&nbsp;</td>\n' +
  '<td style="width: 14.2367%;">&nbsp;</td>\n' +
  '<td style="width: 14.2367%;">&nbsp;</td>\n' +
  '<td style="width: 14.3225%;">&nbsp;</td>\n' +
  '<td style="width: 14.3225%;">&nbsp;</td>\n' +
  '<td style="width: 14.3225%;">&nbsp;</td>\n' +
  '</tr>\n' +
  '</tbody>\n' +
  '</table>';
