import React, {useCallback, useEffect, useRef, useState} from 'react';
import BirthdayModal from '../BirthdayModal/BirthdayModal';
import {getBirthdaysAPI} from '../../../api/user';
import {isSameDayAndMonth} from '../../../common/helpers';
import {getViewFileLink} from '../../../api/files';

function BirthdayService() {
  const modalRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const onClose = useCallback(() => {
    setTimeout(() => {
      setCurrentIndex(prev => prev + 1);
    }, 500);
  }, []);

  useEffect(() => {
    getBirthdaysAPI().then(res => {
      const today = new Date();
      const data = [];
      (res?.data || []).forEach(user => {
        if (user.birthdate && isSameDayAndMonth(today, user.birthdate)) {
          data.push({
            ...user,
            template: user.birthday_template || 0,
            avatar: user.avatar?.id ? getViewFileLink(user.avatar.id) : null,
          });
        }
      });
      setUsers(data);
      setCurrentIndex(0);
    }).catch(console.error);
  }, []);

  useEffect(() => {
    if (currentIndex < users.length) {
      modalRef.current?.handleOpen(users[currentIndex]);
    }
  }, [users, currentIndex]);

  return (
    <BirthdayModal ref={modalRef} onClose={onClose}/>
  );
}

export default BirthdayService;
