import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

class Dashboard extends React.PureComponent {
  render() {
    return (
      <Redirect to={'/tasks/me'}/>
    );
  }
}

export default connect()(Dashboard);
