import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getNotificationsAPI = (params = null) => {
  return Requester.get(`${API_URL}/notifications`, params);
};

export const updateNotificationsAPI = (id, data) => {
  return Requester.put(`${API_URL}/notifications/${id}`, data);
};
