import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import {Avatar} from 'antd';
import './UserAvatar.scss';
import {getViewFileLink} from '../../../api/files';

class UserAvatar extends React.PureComponent {
  render() {
    const {user, showAvatar, showName} = this.props;
    if (!user) {
      return null;
    }
    let avatar = null;
    if (showAvatar) {
      if (user.avatar) {
        avatar = <Avatar icon={<UserOutlined />} src={getViewFileLink(user.avatar.id)}/>
      } else if (!user.name) {
        avatar = <Avatar icon={<UserOutlined />}/>
      } else {
        avatar = <Avatar>{`${user.name}`.charAt(0)}</Avatar>
      }
    }
    return (
      <div className="user-avatar">
        {avatar}
        {
          showName &&
          <span className="user-name">{user.name}</span>
        }
      </div>
    );
  }
}

UserAvatar.propTypes = {
  user: PropTypes.object,
  showAvatar: PropTypes.bool,
  showName: PropTypes.bool,
};

UserAvatar.defaultProps = {
  showAvatar: true,
  showName: true,
};

export default UserAvatar;
