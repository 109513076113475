export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

export const getNotificationsAC = () => ({
  type: GET_NOTIFICATIONS,
});

export const getNotificationsSuccessAC = (data) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  data,
});
