import React from 'react';
import {connect} from 'react-redux';
import { BellOutlined } from '@ant-design/icons';
import { Badge, Dropdown } from 'antd';
import {getNotificationsAC} from '../../../redux/actions';
import NotificationTabs from './NotificationTabs';
import './Notifications.scss';

class Notifications extends React.PureComponent {
  componentDidMount() {
    this.getList();
    this.startIntervalGetList();
    this.updateBadge();
  }

  componentWillUnmount() {
    this.stopIntervalGetList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.notifications.totalUnread !== this.props.notifications.totalUnread) {
      this.updateBadge();
    }
  }

  updateBadge = () => {
    if (window.appAPI?.updateBadge) {
      window.appAPI.updateBadge(this.props.notifications.totalUnread);
    }
  };

  startIntervalGetList = () => {
    this.stopIntervalGetList();
    this.timer = setInterval(this.getList, 60 * 1000);
  };

  stopIntervalGetList = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  };

  getList = () => {
    this.props.dispatch(getNotificationsAC());
  };

  render() {
    const {notifications} = this.props;
    return (
      <Dropdown overlay={
        <NotificationTabs
          all={notifications.all}
          total={notifications.total}
          unread={notifications.unread}
          totalUnread={notifications.totalUnread}
          getList={this.getList}
        />
      } trigger={['click']}>
        <div className="notifications header-icon">
          <Badge count={notifications.totalUnread} overflowCount={5}>
            <BellOutlined />
          </Badge>
        </div>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.common.notifications,
});

export default connect(mapStateToProps)(Notifications);
