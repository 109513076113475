import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Broadcaster from '../../helpers/broadcaster';
import {BROADCAST_EVENTS} from '../../constants/broadcastEvents';

class ImageLightBox extends React.PureComponent {
  state = {
    isOpen: false,
    images: [],
    photoIndex: 0,
  };

  componentDidMount() {
    Broadcaster.on(BROADCAST_EVENTS.OPEN_IMAGE_LIGHT_BOX, this.handleOpen);
  }

  componentWillUnmount() {
    Broadcaster.off(BROADCAST_EVENTS.OPEN_IMAGE_LIGHT_BOX, this.handleOpen);
  }

  handleOpen = (images) => {
    this.setState({
      isOpen: !!images?.length,
      images: images || [],
      photoIndex: 0,
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onMovePrevRequest = () => {
    const {images, photoIndex} = this.state;
    this.setState({
      photoIndex: (photoIndex + images.length - 1) % images.length,
    });
  };

  onMoveNextRequest = () => {
    const {images, photoIndex} = this.state;
    this.setState({
      photoIndex: (photoIndex + 1) % images.length,
    });
  };

  render() {
    const {isOpen, images, photoIndex} = this.state;
    if (!isOpen || !images?.[photoIndex]) {
      return null;
    }
    return (
      <Lightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={this.handleClose}
        onMovePrevRequest={this.onMovePrevRequest}
        onMoveNextRequest={this.onMoveNextRequest}
      />
    );
  }
}

export default ImageLightBox;
