/**
 * Broadcaster service
 * @return {{on: on, off: off, broadcast: broadcast}}
 * @constructor
 */

const HocHTBroadcaster = () => {
  if (!window._hocht_broadcasterEvents) {
    window._hocht_broadcasterEvents = [];
  }
  const events = window._hocht_broadcasterEvents;

  const on = (key, callback = null) => {
    if (typeof callback !== 'function') {
      return;
    }
    // Prevent duplicate event
    for (let i = 0; i < events.length; i++) {
      if (events[i].key === key && events[i].callback === callback) {
        return;
      }
    }
    const event = {key, callback};
    events.push(event);
  };

  const off = (key, callback) => {
    if (typeof callback !== 'function') {
      return;
    }
    const n = events.length;
    for (let i = n - 1; i >= 0; i--) {
      if (events[i].key === key && events[i].callback === callback) {
        events.splice(i, 1);
      }
    }
  };

  const broadcast = (key, data = null) => {
    if (!key) {
      return false;
    }
    events.filter(event => event.key === key).forEach(item => item.callback(data));
  };

  return {
    on,
    off,
    broadcast
  };
};

/**
 * @type {{on, off, broadcast}}
 */
const Broadcaster = HocHTBroadcaster();

export default Broadcaster;
