import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getCustomersAPI = (params = null) => {
  return Requester.get(`${API_URL}/customers`, params);
};

export const getCustomerDetailAPI = (id, params = null) => {
  return Requester.get(`${API_URL}/customers/${id}`, params);
};

export const createCustomerAPI = (data) => {
  return Requester.post(`${API_URL}/customers`, data);
};

export const updateCustomerAPI = (customerId, data) => {
  return Requester.put(`${API_URL}/customers/${customerId}`, data);
};

export const deleteCustomerAPI = (customerId) => {
  return Requester.delete(`${API_URL}/customers/${customerId}`);
};

export const getCustomerNotesAPI = (params = null) => {
  return Requester.get(`${API_URL}/customers/notes`, params);
};

export const getCustomerNoteDetailAPI = (noteId, params = null) => {
  return Requester.get(`${API_URL}/customers/notes/${noteId}`, params);
};

export const createCustomerNoteAPI = (data) => {
  return Requester.post(`${API_URL}/customers/notes`, data);
};

export const updateCustomerNoteAPI = (noteId, data) => {
  return Requester.put(`${API_URL}/customers/notes/${noteId}`, data);
};

export const deleteCustomerNoteAPI = (noteId) => {
  return Requester.delete(`${API_URL}/customers/notes/${noteId}`);
};
