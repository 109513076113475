export const TASK_STATUS = {
  TODO: {
    id: 10,
    name: 'Chưa làm',
    className: 'status-todo',
  },
  IN_PROGRESS: {
    id: 20,
    name: 'Đang xử lý',
    className: 'status-in-progress',
  },
  PENDING: {
    id: 21,
    name: 'Đang treo',
    className: 'status-pending',
  },
  DONE: {
    id: 30,
    name: 'Đã hoàn thành',
    className: 'status-done',
  },
};

export const ALL_TASK_STATUSES = {
  ALL: {
    id: 0,
    name: 'Tất cả',
    className: 'status-all',
  },
  ...TASK_STATUS,
};
