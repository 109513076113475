import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './PageLoading.scss';

class PageLoading extends React.PureComponent {
  render() {
    const {isLoading} = this.props;
    if (!isLoading) {
      return null;
    }
    const antIcon = <LoadingOutlined style={{fontSize: 64}} spin />;
    return (
      <div className="page-loading">
        <Spin indicator={antIcon} tip="Loading"/>
      </div>
    );
  }
}

PageLoading.propTypes = {
  isLoading: PropTypes.bool,
};

export default PageLoading;
