export const SET_LANGUAGE_AC = 'SET_LANGUAGE_AC';
export const NETWORK_STATUS_CHANGED_AC = 'NETWORK_STATUS_CHANGED_AC';

export const setLanguageAC = (language) => ({
  type: SET_LANGUAGE_AC,
  language,
});

export const networkStatusChangedAC = (status) => ({
  type: NETWORK_STATUS_CHANGED_AC,
  status,
});
