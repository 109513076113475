import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class NotificationUpdateTask extends React.PureComponent {
  render() {
    const {notification: {message, params}, markAsRead} = this.props;
    if (!params) {
      return (
        <span>{message}</span>
      );
    }
    return (
      <Link to={`/tasks/detail/${params.taskId}`} className="notification-item" onClick={markAsRead}>
        <strong>{params.userName}</strong> đã cập nhật công việc <strong>{params.taskName}</strong>.
      </Link>
    );
  }
}

NotificationUpdateTask.propTypes = {
  notification: PropTypes.any,
  markAsRead: PropTypes.func,
};

export default NotificationUpdateTask;
