import createReducer from '../createReducer';
import {SET_LANGUAGE_AC} from '../../actions';
import {randomString} from '../../../common/helpers';
import {NETWORK_STATUS_CHANGED_AC} from '../../actions/app';

const initialState = {
  isOnline: navigator.onLine,
  sessionId: '',
  language: 'VI',
  settings: {},
};

initialState.sessionId = randomString();

const handlers = {
  [SET_LANGUAGE_AC]: (state, action) => {
    return {
      ...state,
      language: action.language
    };
  },
  [NETWORK_STATUS_CHANGED_AC]: (state, action) => {
    return {
      ...state,
      isOnline: action.status,
    }
  },
};

export default createReducer(initialState, handlers);
