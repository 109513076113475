import React from 'react';
import ExternalUrl from '../../common/components/HyperLink/ExternalUrl';

class PublicLayout extends React.PureComponent {
  render() {
    const {children} = this.props;
    return (
      <div className="public-layout">
        {
          children
        }
        <footer>
          <p className="copy-right">
            Dong Duong Tax ©2020.
            Developed by <ExternalUrl href="https://vinaetech.com">VinaETech</ExternalUrl>.
          </p>
        </footer>
      </div>
    );
  }
}

export default PublicLayout;
