import {applyMiddleware, combineReducers, compose, createStore as rCreateStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers';
import rootSaga from '../sagas';

//Combine reducers for createStore
const reducersCombined = combineReducers(reducers);

// Saga
const sagaMiddleware = createSagaMiddleware();

//List middleware use in app
const middlewares = [sagaMiddleware];

//List exhancers in app
const exhancers = [applyMiddleware(...middlewares)];

if (process.env.NODE_ENV === 'development') {
  //Add redux devtools extension in firefox and chrome
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    exhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

export const store = (initialState = {}) => {
  return rCreateStore(reducersCombined, initialState, compose(...exhancers));
};


// Create app store
const Store = store();

// Run saga
sagaMiddleware.run(rootSaga);

export default Store;
